import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmployeService {
  public sup_nom : string;
  public sup_email	 : string;
  public sup_phone	 : number;
  public sup_role	 : string;
  public sup_user_id	 : string;
  public sup_type	 : string;
  constructor() { }
}
