import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProduitModel {

  public whp_date : string;
  public whp_categorie : string;
  public whp_entrepot : string;
  public whp_fournis : string;
  public whp_designation : string;
  public whp_qte : number;
  public whp_unite : string;
  public whp_pv : number;
  public whp_description : string;
  public whp_user : string;

}
