import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-app-container',
  templateUrl: './app-container.component.html',
  styleUrls: ['./app-container.component.css']
})
export class AppContainerComponent implements OnInit {
  public logo : string;
  constructor(public location: Location) { }

  ngOnInit() {
    let locationDetect : any = this.location.path();
    let location : any = locationDetect.split('/');
    let finalLocation = "/"+location[1]+'/'+location[2];

        switch(finalLocation){
            case "/app/dailyscoring" :
                this.logo = 'Monitoring Project';
                break;
            case "/app/warehouse" :
              this.logo = 'Warehouse'
              break;
        }
  }

}
