import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NoauthGuardService {

  constructor(private router:Router) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('monitoring_storage')) {
      this.router.navigate(['/project/view']);
        return true;
    }

    return true;
  }

}
