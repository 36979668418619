import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/database/controller/users.service';
import { UserModel } from 'src/app/database/model/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  public user_pwd;
  public user_new_pwd;
  public tab_users : any = [];
  public local_value : any ;
  public user_id : any ;

  constructor(
    public model : UserModel,
    public controller : UsersService
  ) { }

  ngOnInit(): void {
    this.local_value = JSON.parse(localStorage.getItem('monitoring_storage'));
    this.user_id = this.local_value.user_id;
  }

  usersAdd(){
    this.model.user_type = JSON.parse(localStorage.getItem('monitoring_storage')).user_type;
    this.controller.add().subscribe(
      (response:any) =>{
        alert('L\'utilisateur a bien été enregistré');
        this.model.user_email = "";
        this.model.user_name = "";
        this.model.user_phone = "";
        this.model.user_pwd = "";
      },
      (error)=>{
        alert('Une erreur est survenue. veuillez vous reconnecter plustard');
        console.log(error);
      }
    )
  }

  userGet(){
    this.controller.get().subscribe(
      (response: any) => {
        if(response.status == 200){
          this.tab_users = response.data;
        }
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  updatePwd(){
    let table : any ={
      'user_pwd': this.user_pwd,
      'user_new_pwd' : this.user_new_pwd
    };

    this.controller.update_pwd(table,this.user_id)
    .subscribe(
      (response:any) =>{
        if(response.status == 200){
          alert(response.message);
        }
      },
      (error)=>{
        console.log(error);
      }
    )
    /* .then((value)=>{
      console.log(value)
      alert('Le mot de passe a bien été enregistrer');
    })
    .catch((error)=>{
      console.log(error);
      alert('Une erreur est survenue. Essayez plus tard');
    }) */
  }

  updated(){
    /* var triggerEl = document.querySelector('#myTab a[href="#profile"]')
    bootstrap.Tab.getInstance(triggerEl).show() */
  }

}
