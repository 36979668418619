import {Component, OnInit, ElementRef, Input, ViewChild} from '@angular/core';
import {ProjectctrlService} from "../../../database/controller/projectctrl.service";
import {TachectrlService} from "../../../database/controller/tachectrl.service";
import {TachemodelService} from "../../../database/model/tachemodel.service";
import {EmployeController} from "../../../database/controller/employe.service";
import * as moment from 'moment';
@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.css']
})
export class MonitoringComponent implements OnInit{
  public tab :any = [];
  public taches :any = [];
  public agent : any =[];
  public log : any =[];
  public progress : number ;

  @ViewChild('progressDiv') progressbar: ElementRef;

  constructor(public tachesService : TachectrlService ,
              public projetController : ProjectctrlService,
              public tacheModel : TachemodelService,
              public AgentModel: EmployeController,
              public el : ElementRef
   ) {
      moment.locale('fr');
      //this.get();

  }

  ngOnInit(){
    //this.getAgent();
  }

  diffenceDate(date : string){
        let dateB = moment(date,'DD-MM-YYYY');
        let dateC = moment(new Date(),'DD-MM-YYYY');
        return dateB.from(dateC)
  }





  Pclick(){
    window.location.reload();
  }
  public getTache(value){
    /* this.tachesService.get(value)
        .then((data) => {
            if(data.status == 200){
                this.taches = data.data;

                let a : any = [];

                a = data.data.filter((element)=>{
                    return element.value.evolution == 1;
                });

                this.progress  = Math.floor((a.length * 100) / data.data.length);
            }else{
                this.taches = [];
            }
        })
        .catch((err) => {}); */
  }


    public getProgress(value){
        this.tachesService.getLog(value)
            .then((data) => {
                if(data.status == 200){
                    this.log = data.data;
                }else{
                    this.log = [];
                }
            })
            .catch((err) => {});
    }


  add(){
       /*  this.tachesService.add()
            .then((data) =>{
                    console.log(data.data);
            }).catch((err) => {
                alert('une erreur est survenue, veuillez réesayer');
             }); */
  }


}
