import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';
import { SousActiviteDailModel } from '../model/sousactivitedailmodel.service';

@Injectable({
  providedIn: 'root'
})
export class SousactivitesService {

  constructor(
    public http : HttpClient,
    public model : SousActiviteDailModel
  ) { }

  /* public add(){
    return new Promise((resolve,reject)=>{
      this.http.get(_SERVEROOT)
      .subscribe(
        dataSousAct => {
          resolve(dataSousAct);
        },
        err => {
          reject(err)
        }
      )
    })
  }

  public get(){
    return new Promise((resolve,reject)=>{
      this.http.get(_SERVEROOT)
      .subscribe(
        dataSousAct =>{
          resolve(dataSousAct);
        },
        err => {
          reject(err);
        }
      )
    })
  }

  public update(id){
    return new Promise((resolve,reject)=>{
      this.http.put(_SERVEROOT,id)
      .subscribe(
        dataSousAct =>{
          resolve(dataSousAct);
        },
        err => {
          reject(err);
        }
      )
    })
  } */

  public add(){
    return this.http.post(_SERVEROOT.sousActivites_post,this.model);
  }

  public get(){
    return this.http.get(_SERVEROOT.sousActivites_get);
  }

  public get_id(){
    return this.http.get(_SERVEROOT.sousActivites_get_id);
  }

  public update(){
    return this.http.put(_SERVEROOT.sousActivites_update,this.model);
  }

  public delete(){
    return this.http.delete(_SERVEROOT.sousActivites_delete);
  }
}
