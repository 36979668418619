import { Component, OnInit } from '@angular/core';
import {ProjectmodelService} from "../../../database/model/projectmodel.service";
import {ProjectctrlService} from "../../../database/controller/projectctrl.service";
import {EmployeController} from "../../../database/controller/employe.service";
import { Router } from "@angular/router";
import { CategoriesService } from 'src/app/database/controller/categories.service';
import { DatePipe } from '@angular/common';
import { TachectrlService } from 'src/app/database/controller/tachectrl.service';
import { TachemodelService } from 'src/app/database/model/tachemodel.service';
import { ActivitesService } from 'src/app/database/controller/activites.service';
import { ActiviteDailModel } from 'src/app/database/model/activitedailmodel.service';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { FichierService } from 'src/app/database/controller/fichier.service';


@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css'],
  providers: [DatePipe]
})
export class ProjectsComponent implements OnInit {

  myForm = new FormGroup({
    fichier_desig: new FormControl('', [Validators.required]),
    fichier_lien: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required]),
    fichier_etape_id : new FormControl('',[Validators.required])
  });

  public projetTabs : any = [];
  public employe : any = [];
  public catTabs : any = [];
  public paysTabs : any = [];
  public projet = null;
  public taches = null;
  public etapes = null;
  public tab_activite = null;
  public tecTabs : any = [];
  public boolact: boolean = false;
  public boolfichier: boolean = false;


  constructor(
    public EmployeProjet : EmployeController ,
    public model : ProjectmodelService ,
    public controller : ProjectctrlService,
    public catController : CategoriesService,
    public modelTache : TachemodelService,
    public router:Router,
    private datePipe: DatePipe,
    public tacheController : TachectrlService,
    public activiteController : ActivitesService,
    public modelActivite : ActiviteDailModel,
    public fichierController : FichierService,
  ) {
      //this.sup();
  }

  ngOnInit() {
    this.supervisseurGet();
    this.categorieGet();
    this.paysGet();
    this.TechnicienGet();
  }

  paysGet(){
    this.controller.pays().subscribe(
      (response: any) => {
        this.paysTabs = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  get f(){
    return this.myForm.controls;
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const upload$ = this.myForm.patchValue({
        fileSource: file
      });


    }
  }



  projetAdd(){

    //console.log(this.model.proj_date_debut);
    this.controller.add().subscribe(
      (response:any) =>{
        alert('Le projet a bien été enregistré');
        this.model.proj_cat_id = "";
        this.model.proj_date_debut = "";
        this.model.proj_date_fin = "";
        this.model.proj_desc = "";
        this.model.proj_lib = "";
        this.model.proj_sup_id = "";
        this.model.proj_id_pays = "";
        this.model.proj_info_client = "";
        this.model.proj_localisation = "";
      },
      (error)=>{
        alert('Une erreur est survenue. veuillez vous reconnecter plustard');
        console.log(error);
      }
    )
  }

  projetGet(){
    this.projet = null;
    this.taches = null;
    this.etapes = null;
    this.controller.get().subscribe(
      (response: any) => {
        this.projetTabs = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }
 

  deleteProject(id){
    if(!window.confirm("Etes vous sur de bien vouloir supprimer ce projet ?")) return;
    this.controller.delete(id).subscribe(
      (response) => {
        this.projetGet();
        console.log(response);
      },
      (error) => {
        console.log(error)
      }
    )
  }

  projetGet_id(){
    this.controller.get_id().subscribe(
      (response: any) => {
        this.projet = response.projet;
        this.taches = response.taches;
        this.etapes = response.etapes;

      },
    )
  }

  detailProjet(){
    this.controller.details().subscribe(
      (response: any) => {
        this.projet = response.projet;
        this.taches = response.taches;
      },
    )
  }

  supervisseurGet(){
    this.EmployeProjet.get().subscribe(
      (response: any) => {
        this.employe = response;

      },
      (error)=>{
        console.log(error);
      }
    )
  }

  categorieGet(){
    this.catController.get().subscribe(
      (response: any) => {
        if(response.status == 200){
          this.catTabs = response.data;
        }

      },
      (error)=>{
        console.log(error);
      }
    )
  }

  tacheAdd(){
    this.modelTache.tache_proj_id = this.projet.proj_id;

    this.tacheController.add().subscribe(
      (response: any) => {
        if(response.status == 200){
          alert(response.message)
          if(response.status == 200){
            this.taches = response.data;
          }
        }

      },
      (error)=>{
        console.log(error);
      }
    )
  }

  modalComplet(tab){
    this.tab_activite = tab;
    console.log(tab);
  }

  TechnicienGet(){
    this.EmployeProjet.getTech().subscribe(
      (response: any) => {
        this.tecTabs = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  activitePost(){
    this.modelActivite.act_etap_id = this.tab_activite.tache_etap_id;

    this.activiteController.add().subscribe(
      (response: any) => {
        this.modelActivite.act_libelle = "";
        this.modelActivite.act_assigne = "";
        this.modelActivite.act_date_debut = "";
        this.modelActivite.act_date_fin = "";
        this.modelActivite.act_desc = "";
       alert('L\'activité a bien été enregistrée')
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  fichierPhoto(){

    const formData = new FormData();
    formData.append('fichier_lien', this.myForm.get('fileSource').value);
    formData.append('fichier_desig', this.myForm.get('fichier_desig').value);
    formData.append('fichier_etape_id', this.tab_activite.tache_etap_id);

    this.fichierController.photo(formData).subscribe(
      (response:any) =>{
        if(response.status == 200){
          alert(response.message);
        }
        //this.fichierAdd(response.id)
      }
    )

  }


  imgAffiche(img){
    let table = {
      image : img
    }

    this.fichierController.affichephoto(table).subscribe(
      (response:any) =>{
        //window.open(response.data, "_blank");
        //this.image = response.data
      }
    )
  }




}
