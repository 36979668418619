import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';
import { CategorieDailModel } from '../model/categoriesdailmodel.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(
    public http :HttpClient,
    public model : CategorieDailModel
  ) { }

 /*  public add(){
    return new Promise((resolve,reject)=>{
      this.http.post(_SERVEROOT,this.model)
      .subscribe(
        dataCat => {
          resolve(dataCat);
        },
        err => {
          reject(err)
        }
      )
    })
  } */


  public add(){
    return this.http.post(_SERVEROOT.categories_post,this.model);
  }

  public get(){
    return this.http.get(_SERVEROOT.categories_get);
  }

  public get_id(){
    return this.http.get(_SERVEROOT.categories_get_id);
  }

  public update(){
    return this.http.put(_SERVEROOT.categories_update,this.model);
  }

  public delete(){
    return this.http.delete(_SERVEROOT.categories_delete);
  }
}
