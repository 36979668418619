import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';
import { EtapeDailModel } from '../model/etapdailmodel.service';


@Injectable({
  providedIn: 'root'
})
export class EtapesService {

  constructor(
    public http : HttpClient,
    public model : EtapeDailModel
  ) { }

  /* public add(){
    return new Promise((resolve,reject)=>{
      this.http.post(_SERVEROOT, this.model)
      .subscribe(
        dataEtap => {
          resolve(dataEtap)
        },
        err => {
          reject(err)
        }
      )
    })
  } */


  public add(){
    return this.http.post(_SERVEROOT.etapes_post,this.model);
  }

  public get(){
    return this.http.get(_SERVEROOT.etapes_get);
  }

  public get_id(){
    return this.http.get(_SERVEROOT.etapes_get_id);
  }

  public update(){
    return this.http.put(_SERVEROOT.etapes_update,this.model);
  }

  public delete(id){
    
    return this.http.delete(_SERVEROOT.etapes_delete+'etapes/'+ id);
  }
}
