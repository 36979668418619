import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';
import { DepensemodelService } from '../model/depensemodel.service';
import { TachemodelService } from '../model/tachemodel.service';
import { TachectrlService } from './tachectrl.service';

@Injectable({
  providedIn: 'root'
})
export class DepenseService {

  constructor(
    public http :HttpClient,
    public model : DepensemodelService,
    public tacheModel : TachemodelService
  ) { }

  public add(){
    return this.http.post(_SERVEROOT.depenses_post,this.model);
  }

  public list_activity(){
    return this.http.post(_SERVEROOT.depenses_activity,this.tacheModel);
  }

  public list(id){
    return this.http.post(_SERVEROOT.depenses_list,{'actiModel' : id});
  }

  public get(id){
    return this.http.post(_SERVEROOT.depenses_get,{act_etap_id:id});
  }

  public get_id(){
    return this.http.get(_SERVEROOT.depenses_get_id);
  }

  public update(){
    return this.http.put(_SERVEROOT.depenses_update,this.model);
  }

  public delete(id){
    return this.http.post(_SERVEROOT.depenses_delete,{'depense_id':id});
  }
}
