 import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-dailyagents',
  templateUrl: './dailyagents.component.html',
  styleUrls: ['./dailyagents.component.css']
})

export class DailyagentsComponent implements OnInit {
  public agents : any =[];
    constructor() {}

    ngOnInit() {

    }

    
}
