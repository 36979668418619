import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VentesModel {
  public whv_code : string;
  public whv_codeproduit : string;
  public whv_qtsortant : string;
  public whv_somme : string;
  public whv_codentrepot : string;
  public whv_user : string;
  public whv_responsable : string;
  public whv_pv : string;
  public whv_date : string;
  public whv_codeclient : string;
  public whv_typversement : string;

}
