import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DevisDailModel {
  public devis_libelle : string;
  public devis_proj_id : string;
  public devis_commentaire : string;
}
