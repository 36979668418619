import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AgentmappingmodelService {
  public nom:string;
  public prenom:string;
  public phone:string;
  public habitat:string;
  public cni:string;
  constructor() { }
}
