import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';
import { VentesModel } from '../../model/wh/ventes.service';

@Injectable({
  providedIn: 'root'
})
export class VentesService {

  constructor(
    public http: HttpClient,
    public model: VentesModel
  ) { }

  public add(data:any){
    return this.http.post(_SERVEROOT.whventes_post,data);
  }

  public get(){
    return this.http.get(_SERVEROOT);
  }

  public venteglobale(){
    return this.http.get(_SERVEROOT.whventes_globale);
  }

  public venteentrepot(){
    return this.http.get(_SERVEROOT.whventes_entrepot);
  }


}
