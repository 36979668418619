import { Component, OnInit } from '@angular/core';
import { DevisService } from 'src/app/database/controller/devis.service';
import { ProjectctrlService } from 'src/app/database/controller/projectctrl.service';
import { TachectrlService } from 'src/app/database/controller/tachectrl.service';
import { DevisDailModel } from 'src/app/database/model/devisdailmodel.service';
import { TachemodelService } from 'src/app/database/model/tachemodel.service';

@Component({
  selector: 'app-material-quote',
  templateUrl: './material-quote.component.html',
  styleUrls: ['./material-quote.component.css']
})
export class MaterialQuoteComponent implements OnInit {

  public prodTabs = [];
  public prodTabSelect : any = [];
  public prodTabSelectDef : any = [];
  public projetTabs : any = [];
  public etapeTabsId : any = [];
  public activityTabs : any = [];
  public devisTabs : any = [];
  public devisDetailTabs : any = null;

  constructor(
    public devisService : DevisService,
    public projetController : ProjectctrlService,
    public model :TachemodelService,
    public tacheController : TachectrlService,
    public devisDailModel : DevisDailModel
  ) { }

  ngOnInit(): void {
    this.modalDevisDemande();
    this.projetGet();
  }

  modalDevisDemande(){
    this.devisService.listproduit().subscribe(
      (response:any) =>{
        if(response.status == 200){
          this.prodTabs = response.data;
        }
      }
    )
  }

  retablirtabProdSelect(){
    this.prodTabs.forEach(element => {
      for(var i=0; i<this.prodTabSelect.length; i++){
        if(element.whp_code == this.prodTabSelect[i]){
          this.prodTabSelectDef[i]=[
            element.whp_code,
            element.whp_designation,
            0,
          ]
        }
      }
    });

  }

  projetGet(){
    this.projetController.get().subscribe(
      (response: any) => {
        this.projetTabs = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  tacheProjet(){
    this.tacheController.projet_tache().subscribe(
      (response:any) =>{
        this.etapeTabsId = response.etapes;
      }
    )
  }

  tacheEtapes(){
    this.tacheController.etape_tache().subscribe(
      (response:any) =>{
        this.activityTabs = response.activites;
      }
    )
  }

  postDevis(){

    let data = {
      table : this.prodTabSelectDef,
      libelle : this.devisDailModel.devis_libelle,
      comment : this.devisDailModel.devis_commentaire,
      activite : this.devisDailModel.devis_proj_id
    };

    this.devisService.createDevis(data).subscribe(
      (response:any) =>{
        if(response.status == 200){
          alert(response.message);
          this.prodTabSelectDef = [];
        }

      },(error)=>{
        console.log(error);
      }
    )
  }

  listdevis(){
    this.devisService.listdevis().subscribe(
      (response:any)=>{
        if(response.status == 200){
          this.devisTabs = response.devis;
        }
      }
    )
  }

  modaldevis(tab){
    this.devisDetailTabs = tab;
  }

}
