import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/database/controller/wh/client.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {
  public tabs:any = [];

  constructor(
    public controller : ClientService
  ) { }

  ngOnInit(): void {
    this.liste();
  }

  public liste(){
    this.controller.get().subscribe(
      (response:any)=>{
        if(response.status == 200){
          this.tabs = response.data;
        }

      }
    )
  }

}
