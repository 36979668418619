import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MigrationModel {
  public whm_code : string;
  public whm_user : string;
  public whm_codeproduit : string;
  public whm_codentrepot_initial : string;
  public whm_codentrepot_final : string;
  public whm_quantite : string;


}
