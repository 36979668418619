import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.css']
})
export class HomeComponent implements OnInit {
  public type : string = null;


  constructor(public router : Router) { }

  ngOnInit() {
    this.type = JSON.parse(localStorage.getItem('monitoring_storage')).user_type;
  }

}
