import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/database/controller/categories.service';
import { CategorieDailModel } from 'src/app/database/model/categoriesdailmodel.service';

@Component({
  selector: 'app-cat',
  templateUrl: './cat.component.html',
  styleUrls: ['./cat.component.css']
})
export class CatComponent implements OnInit {
  public cattabs : any = [];

  constructor(
    public controler : CategoriesService,
    public model : CategorieDailModel

  ) {

   }



  ngOnInit(): void {
    this.categorieGet();
  }

  categorieAdd(){
    this.controler.add().subscribe(
      (response:any) =>{
        alert('L\'utilisateur a bien été enregistré');
        this.model.cat_desc = "";
        this.model.cat_libelle = "";
      },
      (error)=>{
        alert('Une erreur est survenue. veuillez vous reconnecter plustard');
        console.log(error);
      }
    )
  }

  categorieGet(){
    this.controler.get().subscribe(
      (response: any) => {
        if(response.status == 200){
          this.cattabs = response.data;
        }

      },
      (error)=>{
        console.log(error);
      }
    )
  }

}
