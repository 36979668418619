import { Component, OnInit } from '@angular/core';
import {GroupctrlService} from "../../../database/controller/groupctrl.service";
import { ProjectctrlService } from 'src/app/database/controller/projectctrl.service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent implements OnInit {
    public group: any = [];
    public client: any = [];
    public groupForme: any = [];
    public projet : string;

    constructor(public groupProject: GroupctrlService,
                public projetController: ProjectctrlService,) {
    }

    ngOnInit() {
        this.getagent();
        //this.getGroup();
    }

    getagent() {
        this.groupProject.getJournalier()
            .then((datagroup) => {
                this.client = datagroup.data;
            })
            .catch((err) => {
                alert('une erreur est survenue, veuillez réessayer');
            });
    }

    /* getGroup() {
        this.projetController.get()
            .then((data) => {
                this.groupForme = data.data;
            })
            .catch((err) => {
                alert('une erreur est survenue, veuillez réessayer')
            })
    }

    get(value){
      this.groupProject.get(value)
      .then((data)=>{
        this.group =data.data;
      })
      .catch((err)=>{
        alert('une erreur est survenue, veuillez réessayer')
      })
    } */
}

