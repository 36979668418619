import { Component, OnInit } from '@angular/core';
import { ProjectctrlService } from 'src/app/database/controller/projectctrl.service';
import { TachectrlService } from 'src/app/database/controller/tachectrl.service';
import * as moment from 'moment';
//import {forEach} from "@angular/router/src/utils/collection";
@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.css']
})
export class BudgetComponent implements OnInit {
  public projet : any = [];
  public tache :any =[];
  projets:string;
  public sommeDate : number;
  public sommeFinal :number;
  public imprevu :number;
  public variance :number;
  public finance_value : number;
  constructor( public projetController : ProjectctrlService,
    public tachesService : TachectrlService) {
    moment.locale('fr');
  }

  ngOnInit() {
   // this.get();
  }

  diffenceDate(start : string,end : string){
    let dateA = moment(start,'DD/MM/YYYY');
    let dateB = moment(end,'DD/MM/YYYY');

    return dateB.diff(dateA,'days');
  }

  finance(c :any){
     let diff : number = this.diffenceDate(c.value.start,c.value.end);
     return ((c.value.agent * c.value.coutagent) * diff) + parseInt(c.value.coutmateriaux);
  }



  /* public get(){
    this.projetController.get()
    .then((data)=>{
        this.projet=data.data;
    }).catch((err) => {
      alert('une erreur est survenue, veuillez réesayer');
   });
  } */
  public getTache(value){
    /* this.tachesService.get(value)
        .then((data) => {
                this.tache = data.data;
                this.sommeDate=0;
                this.sommeDate =0;
                this.sommeFinal=0;
                this.variance=0;
                this.finance_value=0;
                this.imprevu=0;
                for(let tache of this.tache){
                  this.finance_value += this.finance(tache);
                  this.sommeDate +=this.diffenceDate(tache.value.start,tache.value.end);
                  this.sommeFinal = this.finance_value + this.imprevu;
                  this.variance=this.sommeFinal-this.finance_value;
                }
        })
        .catch((err) => {}); */
  }
}
