import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FichiermodelService {

  public fichier_desig : string;
  public fichier_lien : File;
  public fichier_sous_activite_id : string;

  constructor() { }
}
