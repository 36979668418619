import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SousActiviteDailModel {

  public sousactiv_libelle : string;
  public sousactiv_descrip : string;
  public sousactiv_act_id : string;
}
