import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';
import { CategorieModel } from '../../model/wh/categorie.service';

@Injectable({
  providedIn: 'root'
})
export class CategorieService {

  constructor(
    public http: HttpClient,
    public model: CategorieModel
  ) { }

  public add(){
    return this.http.post(_SERVEROOT.whcategories_post,this.model);
  }

  public get(){
    return this.http.get(_SERVEROOT.whcategories_get);
  }

  public produit(data:any){
    return this.http.post(_SERVEROOT.whcategories_produit,data);
  }

  public update(id:any){
    return this.http.put(_SERVEROOT.whcategories_update+id,this.model)
  }
}
