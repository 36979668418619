import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {_SERVEROOT} from "../../routes/server-root";
import {ProblemsCrt} from "../model/problems.service";
declare let Promise: any;

@Injectable({
  providedIn: 'root'
})
export class ProblemsService {

  constructor(
    public http: HttpClient,
    public model :ProblemsCrt ) { }


  public add(){
    return this.http.post(_SERVEROOT.problemes_post,this.model);
  }

  public get(id){
    return this.http.post(_SERVEROOT.problemes_get,{'act_etap_id' : id});
  }

  public get_id(){
    return this.http.get(_SERVEROOT.problemes_get_id);
  }

  public update(){
    return this.http.post(_SERVEROOT.problemes_update,this.model);
  }

  public delete(){
    return this.http.delete(_SERVEROOT.problemes_delete);
  }

}
