import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';

@Injectable({
  providedIn: 'root'
})
export class KitController {

  constructor(
    public http: HttpClient,
  ) { }

  public add(data:any){
    return this.http.post(_SERVEROOT.whkit_create,data);
  }

  public list(){
    return this.http.get(_SERVEROOT.whkit_get);
  }


}
