import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';
import { ActiviteDailModel } from '../model/activitedailmodel.service';

@Injectable({
  providedIn: 'root'
})
export class ActivitesService {

  constructor(
    public http: HttpClient,
    public model: ActiviteDailModel
  ) { }

  /*  return new Promise((resolve, reject) => {
      this.http.post(_SERVEROOT,this.model)
      .subscribe(
        dataActiv => {
          resolve(dataActiv);
        },
        err =>{
          reject(err);
        }
      )
    }) */

  public add(){
    return this.http.post(_SERVEROOT.activites_post,this.model);
  }

  public get(id){
    return this.http.post(_SERVEROOT.activites_get,{'id_projet' : id});
  }

  public get_id(){
    return this.http.get(_SERVEROOT.activites_get_id);
  }

  public update(){
    return this.http.put(_SERVEROOT.activites_update,this.model);
  }

  public delete(){
    return this.http.delete(_SERVEROOT.activites_delete);
  }

}
