import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChiefComponent } from './components/chief/chief.component';
import { DailyagentsComponent } from './components/dailyagents/dailyagents.component';
import { ScoringComponent } from './components/scoring/scoring.component';
import { GroupComponent} from './components/group/group.component';
import { ProjectsComponent } from './components/projects/projects.component';
import {DailyscoringService} from "./dailyscoring.service";
import { MonitoringComponent } from './components/monitoring/monitoring.component';
import { IssuesComponent } from './components/issues/issues.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { BudgetComponent } from './components/budget/budget.component';
import { ImprevuComponent } from './components/imprevu/imprevu.component';
import { StepsComponent } from './components/steps/steps.component';
import { ActivityComponent } from './components/activity/activity.component';
import { UnderActiviyComponent } from './components/under-activiy/under-activiy.component';
import { LoginDailComponent } from './login/login.component';
import { UsersDailComponent } from './components/users/users.component';
import { MonitoringProjetComponent } from './components/monitoring-projet/monitoring-projet.component';
import { TacheComponent } from './components/tache/tache.component';
import { BrowserModule } from '@angular/platform-browser';
import { CatComponent } from './components/cat/cat.component';
import { MaterialQuoteComponent } from './components/material-quote/material-quote.component';
import { SpentComponent } from './components/spent/spent.component';
import { OutilsComponent } from './components/outils/outils.component';

@NgModule({
  declarations: [
      ChiefComponent,
    DailyagentsComponent,
    ScoringComponent,
    GroupComponent,
    ProjectsComponent,
    MonitoringComponent,
    IssuesComponent,
    BudgetComponent,
    ImprevuComponent,
    StepsComponent,
    ActivityComponent,
    UnderActiviyComponent,
    LoginDailComponent,
    UsersDailComponent,
    MonitoringProjetComponent,
    TacheComponent,
    CatComponent,
    MaterialQuoteComponent,
    SpentComponent,
    OutilsComponent,
  ],
  exports: [
      ChiefComponent,
    DailyagentsComponent,
    ScoringComponent,
    GroupComponent,
    ProjectsComponent,
    MonitoringComponent,
    IssuesComponent,
    BudgetComponent,
    ImprevuComponent,
    StepsComponent,
    ActivityComponent,
    UnderActiviyComponent,
    LoginDailComponent,
    UsersDailComponent

  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers : [DailyscoringService]
})
export class DailyscoringModule { }
