import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';
import { DonneesousactmodelService } from '../model/donneesousactmodel.service';
import { TachemodelService } from '../model/tachemodel.service';

@Injectable({
  providedIn: 'root'
})
export class DonneesousactService {

  constructor(
    public http :HttpClient,
    public model : DonneesousactmodelService,
    public tachemodel : TachemodelService
  ) { }

  public add(){
    return this.http.post(_SERVEROOT.donnees_post,this.model);
  }

  public get(){
    return this.http.get(_SERVEROOT.donnees_get);
  }

  public list(){
    return this.http.post(_SERVEROOT.donnees_list,this.tachemodel);
  }



  public get_id(){
    return this.http.get(_SERVEROOT.donnees_get_id);
  }

  public update(){
    return this.http.put(_SERVEROOT.donnees_update,this.model);
  }

  public delete(){
    return this.http.delete(_SERVEROOT.depenses_delete);
  }
}
