import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StocksModel {
  public whs_code : string;
  public whs_codentrepot : string;
  public whs_codeproduit : string;
  public whs_qte_initial : string;
  public whs_qte : number;
  public whs_pv : number;
  public whs_totalpv : string;
  public whs_user : string;
  public whs_date : string;
  public whs_design : string;
  public whs_prov : string;


}
