import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { AppchoiceComponent } from './appchoice/appchoice.component';
import {FormsModule} from "@angular/forms";


@NgModule({
  declarations: [
      LoginComponent,
      AppchoiceComponent,
  ],
  exports: [
    LoginComponent,
      AppchoiceComponent
  ],
  imports: [
    FormsModule,
    CommonModule
  ]
})
export class ComponentsModule { }
