import { Component, OnInit } from '@angular/core';
import { SousActiviteDailModel } from 'src/app/database/model/sousactivitedailmodel.service';
import { SousactivitesService } from 'src/app/database/controller/sousactivites.service';
import { ActivitesService } from 'src/app/database/controller/activites.service';

@Component({
  selector: 'app-under-activiy',
  templateUrl: './under-activiy.component.html',
  styleUrls: ['./under-activiy.component.css']
})
export class UnderActiviyComponent implements OnInit {
  public tabs : any = [];
  public tabsActivite : any = [];

  constructor(
    public model : SousActiviteDailModel,
    public controller : SousactivitesService,
    public controllerActivite : ActivitesService
  ) { }

  ngOnInit(): void {
    this.activiteGet();
  }

  sousactivityAdd(){
    this.controller.add().subscribe(
      (response:any) =>{
        alert('Le sous-actvité a bien été enregistré');
        this.model.sousactiv_act_id = "";
        this.model.sousactiv_descrip = "";
        this.model.sousactiv_libelle = "";
      },
      (error)=>{
        alert('Une erreur est survenue. veuillez vous reconnecter plustard');
        console.log(error);
      }
    )
  }

  sousactivityGet(){
    this.controller.get().subscribe(
      (response: any) => {
        this.tabs = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  activiteGet(){
    this.controllerActivite.get('3').subscribe(
      (response: any) => {
        this.tabsActivite = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }

}
