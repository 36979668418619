import { Component, OnInit } from '@angular/core';
import { EntrepotService } from 'src/app/database/controller/wh/entrepot.service';
import { EntrepotModel } from 'src/app/database/model/wh/entrepot.service';
declare let $ : any;

@Component({
  selector: 'app-entrepot',
  templateUrl: './entrepot.component.html',
  styleUrls: ['./entrepot.component.css']
})
export class EntrepotComponent implements OnInit {
  public tabs : any = [];
  public messageUpt : boolean = false;
  public local_value : any ;

  constructor(
    public controller : EntrepotService,
    public model : EntrepotModel
  ) { }

  ngOnInit(): void {
    this.local_value = JSON.parse(localStorage.getItem('monitoring_storage'));
  }

  vider(){
    this.model.whe_description = "";
    this.model.whe_designation = "";
    this.model.whe_emplacement = "";
  }

  entrepotAdd(){
    if(!this.messageUpt){
      this.controller.add().subscribe(
        (response:any) =>{
          alert('L\'entrepot a bien été enregistré');
          this.vider();

        },
      )
    }else{
      this.controller.update(this.model.whe_code).subscribe(
        (response : any)=>{
          alert('Opération réussie')
          this.messageUpt = false;
          this.vider();
        }
      )
    }

  }

  entrepotList(){
    this.controller.get().subscribe(
      (response:any) =>{
        this.tabs = response;
      },
    )
  }


  updateEntrepot(data: any){
    this.messageUpt = true;
    $('#home-tab').tab('show');
    this.model.whe_description = data['whe_description'];
    this.model.whe_designation = data['whe_designation'];
    this.model.whe_emplacement = data['whe_emplacement'];
    this.model.whe_code = data['id']

  }



}
