import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TachemodelService} from "../model/tachemodel.service";
import { _SERVEROOT} from "../../routes/server-root";
declare let Promise : any;
@Injectable({
  providedIn: 'root'
})
export class TachectrlService {

  constructor(
      public http : HttpClient,
      public model :TachemodelService
    )
    { }


  public getLog( log :string){
    return new Promise((resolve,reject)=>{
      this.http.get(_SERVEROOT.getProgress+"?projet="+log)
      .subscribe(
        data => {
          resolve(data);
        },
        err=> {
          reject(err);
        }
      );
    });
  }

  public add(){
    return this.http.post(_SERVEROOT.taches_post,this.model);
  }
  public projet_tache(){
    return this.http.post(_SERVEROOT.taches_projet_get_id,this.model);
  }
  public etape_tache(){
    return this.http.post(_SERVEROOT.taches_etape_get_id,this.model);
  }

  public get(){
    return this.http.get(_SERVEROOT.categories_get);
  }

  public get_id(){
    return this.http.get(_SERVEROOT.categories_get_id);
  }

  public update(){
    return this.http.put(_SERVEROOT.categories_update,this.model);
  }

  public delete(){
    return this.http.delete(_SERVEROOT.categories_delete);
  }
}
