import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { _SERVEROOT } from 'src/app/routes/server-root';

declare let Promise : any;
@Injectable({
  providedIn: 'root'
})
export class PointageService {

  constructor(public http: HttpClient) { }

   public getPointag(projet : string){
    return new Promise ((resolve,reject)=>{
      this.http.get(_SERVEROOT.getPointage+"?projet="+projet)
      .subscribe(
        dataPointage => {
          resolve(dataPointage);
        },
        err => {
          reject(err);
        }
      )
    });
  }

  /* public add(){
    return this.http.post(_SERVEROOT.categories_post,this.model);
  }

  public get(){
    return this.http.get(_SERVEROOT.categories_get);
  }

  public get_id(){
    return this.http.get(_SERVEROOT.categories_get_id);
  }

  public update(){
    return this.http.put(_SERVEROOT.categories_update,this.model);
  }

  public delete(){
    return this.http.delete(_SERVEROOT.categories_delete);
  } */

}
