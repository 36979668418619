import { Component, OnInit } from '@angular/core';
import { DepenseService } from 'src/app/database/controller/depense.service';
import { DonneesousactService } from 'src/app/database/controller/donneesousact.service';
import { EmployeController } from 'src/app/database/controller/employe.service';
import { EtapesService } from 'src/app/database/controller/etapes.service';
import { FichierService } from 'src/app/database/controller/fichier.service';
import { ProjectctrlService } from 'src/app/database/controller/projectctrl.service';
import { TachectrlService } from 'src/app/database/controller/tachectrl.service';
import { DepensemodelService } from 'src/app/database/model/depensemodel.service';
import { DonneesousactmodelService } from 'src/app/database/model/donneesousactmodel.service';
import { FichiermodelService } from 'src/app/database/model/fichiermodel.service';
import { TachemodelService } from 'src/app/database/model/tachemodel.service';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { DevisService } from 'src/app/database/controller/devis.service';
import { DevisDailModel } from 'src/app/database/model/devisdailmodel.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-tache',
  templateUrl: './tache.component.html',
  styleUrls: ['./tache.component.css'],
  providers: [DatePipe]
})
export class TacheComponent implements OnInit {

  myForm = new FormGroup({
    fichier_desig: new FormControl('', [Validators.required]),
    fichier_lien: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required]),
    fichier_sous_activite_id : new FormControl('',[Validators.required])
  });



  public projetTabs : any = [];
  public etapeTabs : any = [];
  public etapeTabsId : any = [];
  public supTabs : any = [];
  public activityTabs : any = [];
  public prodTabs : any = [];
  public projet : any = null;
  public etape : any = null;
  public id_tab_activite : any = null;
  public prodTabSelect : any = [];
  public prodTabSelectDef : any = [];


  constructor(
    public projetController: ProjectctrlService,
    public etapeController : EtapesService,
    public supController : EmployeController,
    public tacheController : TachectrlService,
    public depenseController : DepenseService,
    public fichierController : FichierService,
    public donneesController : DonneesousactService,

    public model :TachemodelService,
    public depenseModel : DepensemodelService,
    public fichierModel : FichiermodelService,
    public donneesModel : DonneesousactmodelService,
    public devisService : DevisService,
    public devisDailModel : DevisDailModel,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.projetGet();
    this.etapeGet();
    this.superviseurGet();
  }

  get f(){
    return this.myForm.controls;
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const upload$ = this.myForm.patchValue({
        fileSource: file
      });


    }
  }


  projetGet(){
    this.projetController.get().subscribe(
      (response: any) => {
        this.projetTabs = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  etapeGet(){
    this.etapeController.get().subscribe(
      (response: any) => {
        if(response.status == 200){
          this.etapeTabs = response.data;
        }
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  superviseurGet(){
    this.supController.getTech().subscribe(
      (response: any) => {
        this.supTabs = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  tacheAdd(){
    this.model.tache_date_debut = new Date(this.model.tache_date_debut);
    this.model.tache_date_debut = this.datePipe.transform(this.model.tache_date_debut, 'dd/MM/yyyy');

    this.model.tache_date_fin = new Date(this.model.tache_date_fin);
    this.model.tache_date_fin = this.datePipe.transform(this.model.tache_date_fin, 'dd/MM/yyyy');

    this.tacheController.add().subscribe(
      (response: any) => {
        if(response.status == 200){
          alert(response.message);

          if(response.tache == 1){
            this.model.tache_comment = "";
            this.model.tache_date_debut = "";
            this.model.tache_date_fin = "";
            this.model.tache_etap_id = "";
            this.model.tache_priority = "";
            this.model.tache_proj_id = "";
            this.model.tache_resp = "";
          }


        }

      },
      (error)=>{
        console.log(error);
      }
    )
  }

  tacheProjet(){
    this.tacheController.projet_tache().subscribe(
      (response:any) =>{
        this.projet = response.projet;
        this.etapeTabsId = response.etapes;
      }
    )
  }


  tacheEtapes(){
    this.tacheController.etape_tache().subscribe(
      (response:any) =>{
        //this.etapeTabsId = response.etap_libelle;
        this.etape = response.etape;
        this.activityTabs = response.activites;
      }
    )
  }


  modalComplet(tab){
    this.id_tab_activite = tab;
  }

  depenseAdd(){
    this.depenseController.add().subscribe(
      (response:any) =>{
        alert('Opération réussie')
      }
    )
  }


  fichierPhoto(){
    const formData = new FormData();
    formData.append('fichier_lien', this.myForm.get('fileSource').value);
    formData.append('fichier_desig', this.myForm.get('fichier_desig').value);
    formData.append('fichier_sous_activite_id', this.myForm.get('fichier_sous_activite_id').value);

    this.fichierController.photo(formData).subscribe(
      (response:any) =>{
        if(response.status == 200){
          alert(response.message);
        }
        //this.fichierAdd(response.id)
      }
    )

  }

  /* fichierAdd(id){
    if(id != 0){
      let table = {
        'fichier_desig': this.myForm.get('fichier_desig').value,
        'fichier_id':  id,
        'fichier_sous_activite_id' : this.myForm.get('fichier_sous_activite_id').value
      }

      this.fichierController.add(table).subscribe(
        (response:any) =>{
          console.log(response)
          alert('L\'opération a bien été enregistré')
        }
      )

    }
  } */

  donneesAdd(){
    this.donneesController.add().subscribe(
      (response:any) =>{
        alert(response.message);
      }
    )
  }

  modalDevisDemande(tab){
    this.devisDailModel.devis_proj_id = tab.act_id;
    this.devisService.listproduit().subscribe(
      (response:any) =>{
        if(response.status == 200){
          this.prodTabs = response.data;
        }

      }
    )
  }

  retablirtabProdSelect(){
    this.prodTabs.forEach(element => {
      for(var i=0; i<this.prodTabSelect.length; i++){
        if(element.whp_code == this.prodTabSelect[i]){
          this.prodTabSelectDef[i]=[
            element.whp_code,
            element.whp_designation,
            0,
          ]
        }
      }
    });

  }


  postDevis(){

    let data = {
      table : this.prodTabSelectDef,
      libelle : this.devisDailModel.devis_libelle,
      comment : this.devisDailModel.devis_commentaire,
      activite : this.devisDailModel.devis_proj_id
    };

    this.devisService.createDevis(data).subscribe(
      (response:any) =>{
        if(response.status == 200){
          alert(response.message);
          this.prodTabSelectDef = [];
        }

      },(error)=>{
        console.log(error);
      }
    )
  }




}
