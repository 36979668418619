import {Component, OnInit} from '@angular/core';
import { Router} from "@angular/router";
import {Location} from '@angular/common';
declare let $:any;



export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    classCss: string;
    Function: any;
}

export const DAILYSCORING: RouteInfo[] = [
    { path: 'dailyscoring/projects', title: 'Projets',  icon: 'fa fa-columns', classCss: '',Function:'' },
    { path: 'dailyscoring/activity', title: 'Activités',  icon: 'fa fa-tasks', classCss: '',Function:'' },
    /* { path: 'dailyscoring/tache', title: 'Tâche',  icon:'fa fa-tasks', classCss: '',Function:'' }, */
    { path: 'dailyscoring/devis', title: 'Disponibilité du matériels',  icon:'fa fa-list', classCss: '',Function:'' },
    { path: 'dailyscoring/outils', title: 'Outils',  icon:'fa fa-wrench', classCss: '',Function:'' },
    /* { path: 'dailyscoring/depenses', title: 'Depense',  icon:'fa fa-money', classCss: '',Function:'' }, */
    /* { path: 'dailyscoring/monitoring/projet', title: 'Suivi de projet',  icon:'fa fa-tasks', classCss: '',Function:'' }, */
    /* { path: 'dailyscoring/issues', title: 'Problème',  icon:'fa fa-exclamation-triangle', classCss: '',Function:'' }, */
    { path: 'dailyscoring/users', title: 'Utilisateurs',  icon: 'fa fa-users', classCss: '',Function:'' },
    { path: 'dailyscoring/category', title: 'Catégories',  icon: 'fa fa-list-alt', classCss: '',Function:'' },
    { path: 'dailyscoring/steps', title: 'Etapes',  icon: 'fa fa-step-forward', classCss: '',Function:'' },
    /*
    { path: 'dailyscoring/under-activity', title: 'Sous-Activités',  icon: 'fa fa-tasks', classCss: '',Function:'' }, */
    { path: 'dailyscoring/employes', title: 'Superviseurs et Techniciens',  icon:'fa fa-user', classCss: '',Function:'' },

    /* { path: 'dailyscoring/monitoring', title: 'Suivi de projet',  icon:'fa fa-tasks', classCss: '',Function:'' },
    { path: 'dailyscoring/pointage', title: 'Pointage',  icon:'fa fa-list-alt', classCss: '',Function:'' },
    { path: 'dailyscoring/groupe', title: 'groupe de travail',  icon:'fa fa-users', classCss: '',Function:'' },
    { path: 'dailyscoring/budget', title: 'Gestion budget',  icon:'fa fa-money', classCss: '',Function:'' },
    { path: 'dailyscoring/issues', title: 'Problème',  icon:'fa fa-exclamation-triangle', classCss: '',Function:'' },
    { path: 'dailyscoring/imprevu', title: 'Gestion imprévu',  icon:'fa fa-money', classCss: '',Function:'' }, */
    { path: 'settings', title: 'Administration',  icon:'fa fa-cog', classCss: '',Function:'' }
];


export const WAREHOUSE: RouteInfo[] = [
  { path:'warehouse/users' , title: 'Utilisateurs', icon: 'fa fa-user', classCss: '', Function: ''  },
  { path:'warehouse/fournisseurs' , title: 'Fournisseurs', icon: 'fa fa-users', classCss: '', Function: ''  },
  { path:'warehouse/entrepots' , title: 'Entrepots', icon: 'fa fa-home', classCss: '', Function: ''  },
  { path:'warehouse/categories' , title: 'Catégories', icon: 'fa fa-list-alt', classCss: '', Function: ''  },
  { path:'warehouse/produits' , title: 'Produits', icon: 'fa fa-table', classCss: '', Function: ''  },
  { path:'warehouse/stocks' , title: 'Stocks', icon: 'fa fa-shopping-cart', classCss: '', Function: ''  },
  { path:'warehouse/outils' , title: 'Outils', icon: 'fa fa-wrench', classCss: '', Function: ''  },
  { path:'warehouse/kits' , title: 'Kits', icon: 'fa fa-archive', classCss: '', Function: ''  },
  { path:'warehouse/devis' , title: 'Devis', icon: 'fa fa-clipboard', classCss: '', Function: ''  },
  { path:'warehouse/clients' , title: 'Clients', icon: 'fa fa-users', classCss: '', Function: ''  },
  { path:'warehouse/approvionnements' , title: 'Approvisionnements', icon: 'fa fa-cart-plus', classCss: '', Function: ''  },
  { path:'warehouse/ventes' , title: 'Ventes', icon: 'fa fa-cart-arrow-down', classCss: '', Function: ''  },
  { path:'warehouse/migrations' , title: 'Migrations', icon: 'fa fa-repeat', classCss: '', Function: ''  },
  { path:'warehouse/bilan-financier' , title: 'Bilan financier', icon: 'fa fa-money', classCss: '', Function: ''  },

];

@Component({
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls :['sidebar.component.css']
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public v_status: number = 0;
    public routeActive : boolean = true;

    public ROUTES : RouteInfo;

    constructor(public location: Location,protected router: Router){}
    ngOnInit() {

    let locationDetect : any = this.location.path();
    let location : any = locationDetect.split('/');
    let finalLocation = "/"+location[1]+'/'+location[2];


        switch(finalLocation){
            case "/app/dailyscoring" :
                this.menuItems = DAILYSCORING.filter(menuItem => menuItem);
                break;

            case "/app/warehouse" :
              this.menuItems = WAREHOUSE.filter(menuItem => menuItem)
              break;
        }


    }

	deconnexion(){
		    //this.cookieService.deleteAll();
            /* this.cookie.delete('__asfaserv','/');
            window.location.href=""; */
            localStorage.removeItem('monitoring_storage');
            this.router.navigate(['/login']);
	}

    isNotMobileMenu(){
        if($(window).width() > 991){
            return false;
        }
        return true;
    }
}
