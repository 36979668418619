import { Component, OnInit,TemplateRef } from '@angular/core';
import { CategorieService } from 'src/app/database/controller/wh/categorie.service';
import { EntrepotService } from 'src/app/database/controller/wh/entrepot.service';
import { FournisseurService } from 'src/app/database/controller/wh/fournisseur.service';
import { ProduitService } from 'src/app/database/controller/wh/produit.service';
import { ProduitModel } from 'src/app/database/model/wh/produit.service';
declare let $ : any;


@Component({
  selector: 'app-produit',
  templateUrl: './produit.component.html',
  styleUrls: ['./produit.component.css']
})
export class ProduitComponent implements OnInit {
  public tabsCat : any = [];
  public tabsEnt : any = [];
  public tabsFour : any = [];
  public tabsType : any = [];
  public tabsProd : any = [];
  public code:string;
  public nomProd:string;
  produitForm;
  searchText;
  public limit:number = 0;
  public success:number=0;
  public messageUpt : boolean = false;
  public local_value : any ;

  constructor(
    public categorieController : CategorieService,
    public entreprotController : EntrepotService,
    public fournisseurController : FournisseurService,
    public controller : ProduitService,
    public model : ProduitModel
  ) {
    /* this.produitForm = this.formBuilder.group({
      whp_designation :null,
      initiale :null ,
      unite :null ,
      prixvente :null ,
      description :null,
      categorie :null,
      entrepot :null,
      fournisseur :null,
      qteinitial: null,
      user : JSON.parse(localStorage.getItem('monitoring_storage')).user_name,
      save:0,
      date:null
    }) */
   }


  ngOnInit(): void {
    this.get();
    this.limit = 0;
    this.local_value = JSON.parse(localStorage.getItem('monitoring_storage'));

  }

  get(){
    this.categorieController.get().subscribe(
      (response: any) =>{
        this.tabsCat = response.data;
      }
    )
    this.entreprotController.get().subscribe(
      (response: any) =>{
        this.tabsEnt = response;
      }
    )
    this.fournisseurController.get().subscribe(
      (response: any) =>{
        this.tabsFour = response;
      }
    )
    this.controller.getType().subscribe(
      (response:any)=>{
        this.tabsType = response;
      }
    )

  }

  public functsearch(){
    let data = {
      'search' : this.searchText
    }

    this.controller.search(data).subscribe(
      (response:any) =>{
        if(response.status == 200){
          this.tabsProd = response.data;
        }
      }
    )
  }

  public saveAnProd(){
    let data = {
      'save' : 1,
      'secret' : this.code,
      'fournisseur' : this.model.whp_fournis,
      'user' : JSON.parse(localStorage.getItem('monitoring_storage')).user_id,
      'qteinitial' : this.model.whp_qte,
      'prixvente' : this.model.whp_pv,
      'entrepot'  : this.model.whp_entrepot,
      'date' : this.model.whp_date

    };

    this.controller.post(data).subscribe(
      (response :any) => {
        if(response.status == 200){
          if(response.data == 1){
            alert(response.message);
          }else{
            $('#myModal').modal('hide');
            alert('Les données ont bien été enregistrée');
          }


        }
      }
    )
  }

  vider(){
    this.model.whp_categorie = ""
    this.model.whp_date = ""
    this.model.whp_description = ""
    this.model.whp_designation = ""
    this.model.whp_entrepot = ""
    this.model.whp_fournis = ""
    this.model.whp_pv = 0
    this.model.whp_qte = 0
    this.model.whp_unite = ""
  }

  public save(){
    if(!this.messageUpt){
      let data = {
        'whp_designation' : this.model.whp_designation,
        'initiale' : this.model.whp_qte,
        'unite' : this.model.whp_unite,
        'prixvente' : this.model.whp_pv,
        'description': this.model.whp_description,
        'categorie' : this.model.whp_categorie,
        'entrepot' : this.model.whp_entrepot,
        'fournisseur' : this.model.whp_fournis,
        'qteinitial' : this.model.whp_qte,
        'user' : JSON.parse(localStorage.getItem('monitoring_storage')).user_id,
        'save':0,
        'date' : this.model.whp_date
      };

      this.controller.post(data).subscribe(
        (reponse:any)=>{
          if(reponse.status == 200){
            alert('Les données ont bien été enregistrée');
            this.vider();

          }
        },(error)=>{
          if(error.status == 422){
            alert('Veuillez renseigner tous les champs');

          }
        }
      )


    }else{
      this.controller.update(this.model.whp_user).subscribe(
        (response:any)=>{
          alert('Opération réussi');
          this.vider();
          location.reload();
        }
      )
    }




  }

  public list(){
    this.controller.get({limit:this.limit}).subscribe(
      (response: any) =>{
        if(response.status == 200){
          response.data.forEach(element => {
            this.tabsProd.push(element);
          });
          this.limit +=10;
        }
      },
      (error)=>{
        console.log(error)
      }
    )
  }

  public ajout(codProd:any, nom:any){
    $('#myModal').modal('show');
    this.code = codProd;
    this.nomProd = nom;
  }

  public updateProd(data : any){

    this.messageUpt = true;
    $('#home-tab').tab('show');

    this.model.whp_categorie = data['whp_categorie'];
    this.model.whp_description = data['whp_description'];
    this.model.whp_designation = data['whp_designation'];
    this.model.whp_unite = data['whp_unite'];
    this.model.whp_user = data['id'];


  }



}
