import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApprovisionnementModel {
  public wha_code : string;
  public wha_codeproduit : string;
  public wha_user : string;
  public wha_fournisseur : string;
  public wha_entrepot : string;
  public wha_quantite : string;
  public wha_date : string;

}
