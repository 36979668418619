import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ActiviteDailModel {
  public act_libelle : string;
  public act_desc : string;
  public act_etap_id : string;
  public act_assigne : string;
  public act_date_debut : string;
  public act_date_fin : string;
}
