import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EntrepotModel {
  public whe_code : string;
  public whe_designation : string;
  public whe_description : string;
  public whe_emplacement : string;
}
