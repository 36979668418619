import { Component, OnInit } from '@angular/core';
import { OutilsServicemt } from 'src/app/database/controller/outils.service';
import { ProjectctrlService } from 'src/app/database/controller/projectctrl.service';

@Component({
  selector: 'app-outils',
  templateUrl: './outils.component.html',
  styleUrls: ['./outils.component.css']
})
export class OutilsComponent implements OnInit {

  public projetTabs : any = [];
  public projet : string = null;
  public tabs : any = null;

  constructor(
    public projetController: ProjectctrlService,
    public outilsService : OutilsServicemt
  ) { }

  ngOnInit(): void {
    this.projetGet();
  }

  projetGet(){
    this.projetController.get().subscribe(
      (response: any) => {
        this.projetTabs = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  list(){
    let table = {
      projet : this.projet
    };

    this.outilsService.list(table).subscribe(
      (response: any) => {
        if(response.status == 200){
          this.tabs = response.data;
        }
      },
    )
  }

}
