import {Route, Routes} from "@angular/router";
import {LoginDailComponent} from "./dailyscoring/login/login.component";
import {LoginWahComponent} from "./warehouse/login/login.component";
import {UsersComponent} from "./warehouse/component/users/users.component";
import {AppchoiceComponent} from "./components/appchoice/appchoice.component";
import {AppContainerComponent} from "./app-container/app-container.component";
import {ChiefComponent} from "./dailyscoring/components/chief/chief.component";
import {ScoringComponent} from "./dailyscoring/components/scoring/scoring.component";
import {GroupComponent} from "./dailyscoring/components/group/group.component";
import {HomedailyComponent} from "./dailyscoring/homedaily/homedaily.component";
import {HomeComponent} from "./home/home.component";
import {ProjectsComponent} from "./dailyscoring/components/projects/projects.component";
import {MonitoringComponent} from "./dailyscoring/components/monitoring/monitoring.component";
import {IssuesComponent} from "./dailyscoring/components/issues/issues.component";
import {AuthGuardService,} from "./services/auth-guard.service";
import {BudgetComponent} from "./dailyscoring/components/budget/budget.component";
import {ImprevuComponent} from "./dailyscoring/components/imprevu/imprevu.component";
import { StepsComponent } from "./dailyscoring/components/steps/steps.component";
import { UnderActiviyComponent } from "./dailyscoring/components/under-activiy/under-activiy.component";
import { ActivityComponent } from "./dailyscoring/components/activity/activity.component";
import { WarehouseComponent } from "./warehouse/component/component.component";
import { FournisseursComponent } from "./warehouse/component/fournisseurs/fournisseurs.component";
import { EntrepotComponent } from "./warehouse/component/entrepot/entrepot.component";
import { CategorieComponent } from "./warehouse/component/categorie/categorie.component";
import { ProduitComponent } from "./warehouse/component/produit/produit.component";
import { StockComponent } from "./warehouse/component/stock/stock.component";
import { ClientComponent } from "./warehouse/component/client/client.component";
import { ApprovisionnementComponent } from "./warehouse/component/approvisionnement/approvisionnement.component";
import { VentesComponent } from "./warehouse/component/ventes/ventes.component";
import { MigationsComponent } from "./warehouse/component/migations/migations.component";
import { BilanFinancierComponent } from "./warehouse/component/bilan-financier/bilan-financier.component";
import { LoginComponent } from "./components/login/login.component";
import { UsersDailComponent } from "./dailyscoring/components/users/users.component"
import { NoauthGuardService } from "./services/noauth-guard.service";
import { MonitoringProjetComponent } from "./dailyscoring/components/monitoring-projet/monitoring-projet.component";
import { TacheComponent } from "./dailyscoring/components/tache/tache.component";
import { WarehouseGuardService } from "./services/warehouse-guard.service";
import { KitComponent } from "./warehouse/component/kit/kit.component";
import { DevisComponent } from "./warehouse/component/devis/devis.component";
import { CatComponent } from "./dailyscoring/components/cat/cat.component";
import { MaterialQuoteComponent } from "./dailyscoring/components/material-quote/material-quote.component";
import { SpentComponent } from "./dailyscoring/components/spent/spent.component";
import { OutilsComponent } from "./dailyscoring/components/outils/outils.component";
import { WhoutilsComponent } from "./warehouse/component/whoutils/whoutils.component";


export const appRoutes : Routes = [
    {
        path:'',
        redirectTo:'login',
        pathMatch:'full'
    },
    {
        path : 'project/view',
        component : HomeComponent,
        //canActivate : [AuthGuardService]
    },
    {
      path: 'login',
      component: LoginDailComponent,
      canActivate : [NoauthGuardService],
    },
    {
        path :'app',
        component : AppContainerComponent,

        children : [
            {
                path : 'dailyscoring',
                component : HomedailyComponent,
                canActivate : [AuthGuardService],
                children :[
                    {
                      path: 'users',
                      component: UsersDailComponent
                    },
                    {
                      path: 'category',
                      component: CatComponent
                    },
                    {
                      path: 'steps',
                      component: StepsComponent
                    },
                    {
                      path: 'under-activity',
                      component: UnderActiviyComponent
                    },
                    {
                      path: 'activity',
                      component: ActivityComponent
                    },
                    {
                        path: 'projects',
                        component: ProjectsComponent,
                    },
                    {
                        path: 'issues',
                        component: IssuesComponent,
                    },
                    {
                        path: 'employes',
                        component: ChiefComponent,
                    },
                    {
                      path: 'tache',
                      component: TacheComponent,
                    },
                    {
                      path: 'outils',
                      component: OutilsComponent,
                    },
                    {
                      path: 'depenses',
                      component: SpentComponent,
                    },
                    {
                      path: 'devis',
                      component: MaterialQuoteComponent,
                    },
                    {
                      path: 'monitoring/projet',
                      component: MonitoringProjetComponent,
                    },
                    {
                        path: 'monitoring',
                        component: MonitoringComponent,
                    },
                    {
                        path: 'pointage',
                        component: ScoringComponent,
                    },
                    {
                        path: 'groupe',
                        component: GroupComponent,
                    },
                    {
                        path: 'budget',
                        component: BudgetComponent,
                    },
                    {
                        path: 'imprevu',
                        component: ImprevuComponent,
                    }
                 ]
            },
            {
              path: 'warehouse',
              component: WarehouseComponent,
              canActivate : [WarehouseGuardService],
              children: [

                {
                  path: 'users',
                  component: UsersComponent
                },
                {
                  path : 'fournisseurs',
                  component: FournisseursComponent
                },
                {
                  path : 'kits',
                  component: KitComponent
                },
                {
                  path : 'devis',
                  component: DevisComponent
                },

                {
                  path: 'entrepots',
                  component: EntrepotComponent
                },
                {
                  path: 'outils',
                  component: WhoutilsComponent,

                },

                {
                  path: 'categories',
                  component: CategorieComponent
                },
                {
                  path: 'produits',
                  component : ProduitComponent
                },
                {
                  path: 'stocks',
                  component: StockComponent
                },
                {
                  path: 'clients',
                  component: ClientComponent
                },
                {
                  path: 'approvionnements',
                  component: ApprovisionnementComponent
                },
                {
                  path: 'ventes',
                  component: VentesComponent
                },
                {
                  path: 'migrations',
                  component: MigationsComponent
                },
                {
                  path:'bilan-financier',
                  component: BilanFinancierComponent
                }
              ]
            }
        ]
    }

];


