import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';
import { ProduitModel } from '../../model/wh/produit.service';

@Injectable({
  providedIn: 'root'
})
export class ProduitService {
  public categorie :any = [];
  public entrepot :any = [];
  public fournisseur :any = [];

  constructor(
    public http: HttpClient,
    public model: ProduitModel
  ) {
    this.getCEF()
   }

  /* public add(){
    return this.http.post(_SERVEROOT,this.model);
  } */

  public selectCEF(){
    return this.http.get(_SERVEROOT.whproduits_get_select);
  }

  public post(data:any){
    return this.http.post(_SERVEROOT.whproduits_post,data);
  }

  public get(data:any){
    return this.http.post(_SERVEROOT.whproduits_list,data);
  }

  public getType(){
    return this.http.get(_SERVEROOT.whtypes_get);
  }

  public update(id:any){
    return this.http.put(_SERVEROOT.whproduits_update+id, this.model)
  }

  public search(var_search:any){
    return this.http.post(_SERVEROOT.whproduits_search, var_search);
  }

  public getCEF(){
    this.categorie = [];
    this.entrepot = [];
    this.fournisseur = [];
    this.selectCEF().subscribe(
      (reponse:any)=>{

        if(reponse.status == 200){
          this.categorie = reponse.data.categorie ;
          this.entrepot = reponse.data.entrepot;
          this.fournisseur = reponse.data.fournisseur;
        }else{


        }
      }
    )
  }
}
