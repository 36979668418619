import { Component, OnInit } from '@angular/core';
import { EntrepotService } from 'src/app/database/controller/wh/entrepot.service';
import { KitController } from 'src/app/database/controller/wh/kit.service';
import { StocksService } from 'src/app/database/controller/wh/stocks.service';
import { StocksModel } from 'src/app/database/model/wh/stocks.service';
declare let $ : any;

@Component({
  selector: 'app-kit',
  templateUrl: './kit.component.html',
  styleUrls: ['./kit.component.css']
})
export class KitComponent implements OnInit {
  public tabsEnt : any = [];
  public tabskit : any = [];
  public modelEnt:string;
  public list:number;
  public tableEntrant:any = [];
  public valVenteForm:any = [];
  public tabkitdetail:any = [];
  public mnt: number = 0;
  public libelle: string = "";
  public description: string = "";
  public local_value : any ;

  constructor(
    public entreprotController : EntrepotService,
    public stockController : StocksService,
    public model : StocksModel,
    public kitController : KitController
  ) { }

  ngOnInit(): void {
    this.local_value = JSON.parse(localStorage.getItem('monitoring_storage'));
    this.get();
    this.list = 0;

  }


  get(){
    this.entreprotController.get().subscribe(
      (response: any) =>{
        this.tabsEnt = response;
      }
    )
  }

  public supprimer(id:number){
    this.valVenteForm.splice(id,1);
  }


  liste(){
    this.stockController.list({modelEnt : this.modelEnt}).subscribe(
      (response:any)=>{
        if(response.status == 200){
          this.list = 10;
          this.tableEntrant = response.data.prod;
          $('#myModal').modal('show');
        }
      }
    )
  }

  modalProdExt(){
    $('#prodExt').modal('show');
  }


  public ajouter(id:any,code:any,designation:string,qte:number,mnt:string,provenance:string){

    let t :any = document.getElementById("inputy"+id);
    let m:any = document.getElementById("montant"+id);
    let puPrincipale = parseInt(mnt.replace(/ /g,""));

    if(m.value == 0){
      m.value = parseInt(mnt.replace(/ /g,""));
    }

    if(qte < t.value){
      alert('La quantité demander n\'est pas disponible')
    }else{
      let mt = t.value * m.value;
      this.valVenteForm.push({
        qte:t.value,
        codeStock:code,
        desig:designation,
        montant:m.value,
        provenance: provenance,
        monT:mt,
        puPrincipale:puPrincipale,
        type:1
      });

    }
  }


  public ajoutProdExt(){
    this.mnt = this.model.whs_qte * this.model.whs_pv;
    this.valVenteForm.push({
      qte:this.model.whs_qte,
      codeStock:"",
      desig: this.model.whs_design,
      montant: this.model.whs_pv,
      provenance: this.model.whs_prov,
      monT: this.mnt,
      puPrincipale:this.model.whs_pv,
      type:2
    });
  }

  public modalvalidKit(){
    $('#myModal').modal('hide');

  }


  public createkit(){
    let table = {
      data : this.valVenteForm,
      description: this.description,
      libelle: this.libelle
    };

    this.kitController.add(table).subscribe(
      (response:any)=>{
        if(response.status == 200){
          this.valVenteForm = [];
          this.libelle = "";
          this.description = "";
          alert('Le kit a bien été enregistrer.')

        }
      }
    )

  }

  public listeKit(){
    this.kitController.list().subscribe(
      (response:any)=>{
        if(response.status == 200){
          this.tabskit = response.data;
        }

      }
    )
  }

  public modalkit(tab){
    $('#kitDetailModal').modal('show');
    this.tabkitdetail = tab;

  }

}
