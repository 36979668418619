import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';
import { OutilModel } from '../../model/wh/outil.service';

@Injectable({
  providedIn: 'root'
})
export class OutilsService {

  constructor(
    public http: HttpClient,
    public modelOutils : OutilModel
  ) { }


  public listoutilscat(){
    return this.http.get(_SERVEROOT.whoutils_cat);
  }

  public create(){
    return this.http.post(_SERVEROOT.whoutils_post,this.modelOutils);
  }

  public list(){
    return this.http.get(_SERVEROOT.whoutils_get);
  }

  public listCatProduit(cat){
    return this.http.post(_SERVEROOT.whoutils_cat,cat);
  }

  public createEntree(table:any){
    return this.http.post(_SERVEROOT.whoutils_entrees,table);
  }

  public listEntree(){
    return this.http.get(_SERVEROOT.whoutils_entrees_list);
  }

  public listSortie(){
    return this.http.get(_SERVEROOT.whoutils_sortie_list);
  }

  public createSortie(table:any){
    return this.http.post(_SERVEROOT.whoutils_sortie_post,table);
  }

  public sortieList(){
    return this.http.get(_SERVEROOT.whoutils_sortie_get);
  }

  public retouroutils(table:any){
    return this.http.post(_SERVEROOT.whoutils_retour,table);
  }

  public retouroutilsList(){
    return this.http.get(_SERVEROOT.whoutils_retour);
  }
}
