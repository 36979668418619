import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {_SERVEROOT} from "../../routes/server-root";
import {EmployeService} from "../model/employe.service";
declare let Promise : any;

@Injectable({
  providedIn: 'root'
})

export class EmployeController {
  constructor(public http : HttpClient,public model:EmployeService) { }


  public add(){
    return this.http.post(_SERVEROOT.superviseurs_post,this.model);
  }

  public get(){
    return this.http.get(_SERVEROOT.superviseurs_get);
  }

  public getTech(){
    return this.http.get(_SERVEROOT.techniciens_get);
  }

  public get_id(){
    return this.http.get(_SERVEROOT.superviseurs_get_id);
  }

  public update(){
    return this.http.put(_SERVEROOT.superviseurs_update,this.model);
  }

  public delete(){
    return this.http.delete(_SERVEROOT.superviseurs_delete);
  }
}
