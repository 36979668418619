import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';
import { UserModel } from '../model/user.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    public http : HttpClient,
    public model : UserModel
  ) { }


  public add(){
    return this.http.post(_SERVEROOT.users_post,this.model);
  }

  public get(){
    return this.http.get(_SERVEROOT.users_get);
  }

  public get_id(){
    return this.http.get(_SERVEROOT.users_get_id);
  }

  public update(){
    return this.http.put(_SERVEROOT.users_update,this.model);
  }

  public delete(){
    return this.http.delete(_SERVEROOT.categories_delete);
  }

  public update_pwd(table,id){
    return this.http.put(_SERVEROOT.update_pwd+'/'+id, table);

  }

  /* public get(){
    return new Promise((resolve,reject)=>{
      this.http.get(_SERVEROOT.user_get_monit)
      .subscribe(
        data =>{
          resolve(data)
        },
        err => {
          reject(err)
        }
      )
    })
  } */


}
