import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import {map, catchError, tap, finalize} from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterceptService implements HttpInterceptor {

  token: string;
  progressRef : NgProgressRef;
  constructor(public progress:NgProgress, private router:Router) {
    this.token = localStorage.getItem('monitoring_storage') ? JSON.parse(localStorage.getItem('monitoring_storage')).token : null;
    this.progressRef = this.progress.ref('myProgress');
  }

  intercept ( request : HttpRequest <any>, next: HttpHandler): Observable <HttpEvent <any>> {
    this.progressRef.start();
    if (this.token != null){
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('monitoring_storage')).token}`
        }
      });
    }
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status === 200) {
         // this.toastr.success('Success', event.body.message);
         this.progressRef.complete();
         //alert(event.body.message);
        }

      }),
      catchError(error => {
        // Checking if it is an Authentication Error (401)
        this.progressRef.complete();
        if (error.status === 401 ) {
          localStorage.removeItem('monitoring_storage');
          this.router.navigate(['/login']);
          return throwError(error);
        }
        if (error.status === 400 ) {
          return throwError(error);
        }
        // If it is not an authentication error, just throw it
        return throwError(error);
      })
    );
  }

}
