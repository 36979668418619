//import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { ApprovisionnementService } from '../database/controller/wh/approvisionnement.service';
import { MigrationService } from '../database/controller/wh/migration.service';
import { StocksService } from '../database/controller/wh/stocks.service';
import { VentesService } from '../database/controller/wh/ventes.service';


@Injectable()
export class ExcelService {

  constructor(
    public venteController: VentesService,
    public apController: ApprovisionnementService,
    public migController: MigrationService,
    public stockController : StocksService,
  ) { }

  generateExcelVenteEntrepot(){
    this.venteController.venteentrepot().subscribe(
      (response:any)=>{
        if(response.status == 200){
          let entrepot = response.data;
          let workbook = new Workbook();
          let worksheet = null;

          const header = ["Code","Date","Responsable", "Client", "Type","Produit",'Prix unitaire','Quantité','Total'];
          let data = [];

          entrepot.forEach(element => {
            worksheet = workbook.addWorksheet(element.whe_designation);

          });

          workbook.eachSheet(function(worksheet, sheetId) {

            worksheet.addRow([]);
            let headerRow = worksheet.addRow(header);
            headerRow.eachCell((cell, number) => {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF00' },
                bgColor: { argb: '757575' }
              }
              cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
            })


            worksheet.getColumn(1).width = 40;
            worksheet.getColumn(2).width = 30;
            worksheet.getColumn(3).width = 30;
            worksheet.getColumn(4).width = 30;
            worksheet.getColumn(5).width = 30;
            worksheet.getColumn(6).width = 30;
            worksheet.getColumn(7).width = 40;
            worksheet.addRow([]);


          });

          for(var j=0; j<entrepot.length; j++){
            data = [];
            worksheet = workbook.getWorksheet(entrepot[j].whe_designation);

            for(var i=0; i < entrepot[j].vente.length; i++){
              data[i]= [
                entrepot[j].vente[i].whv_code,
                entrepot[j].vente[i].whv_date,
                entrepot[j].vente[i].whe_designation,
                entrepot[j].vente[i].whv_responsable,
                entrepot[j].vente[i].whcl_nom,
                entrepot[j].vente[i].whv_typversement,
                entrepot[j].vente[i].whp_designation,
                entrepot[j].vente[i].whv_pv,
                entrepot[j].vente[i].whv_qtsortant,
                entrepot[j].vente[i].whv_somme,

              ];
            }

            if( data.length != 0){
              for(var j=0; j<data.length; j++){
                worksheet.addRow(data[j]);
              }
            }

            workbook.xlsx.writeBuffer().then((data) => {
              let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              fs.saveAs(blob, 'vente_par_entrepot.xlsx');
            })

          }

        }
      }
    )
  }


  generateExcel(){
    this.venteController.venteglobale().subscribe(
      (response:any)=>{
        if(response.status == 200){
          const header = ["Code","Date","Entrepot","Responsable", "Client", "Type","Produit",'Prix unitaire','Quantité','Total'];
          const title = 'Vente globale';
          let data = [];
          const vente = response.data;


          for(var i=0; i < vente.length; i++){
            data[i]= [
              vente[i].whv_code,
              vente[i].whv_date,
              vente[i].whe_designation,
              vente[i].whv_responsable,
              vente[i].whcl_nom,
              vente[i].whv_typversement,
              vente[i].whp_designation,
              vente[i].whv_pv,
              vente[i].whv_qtsortant,
              vente[i].whv_somme,

            ];
          }



          console.log(data);

          let workbook = new Workbook();
          let worksheet = workbook.addWorksheet('ventes');

          let titleRow = worksheet.addRow([title]);
          titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true }
          worksheet.addRow([]);

          let headerRow = worksheet.addRow(header);


          headerRow.eachCell((cell, number) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFFFF00' },
              bgColor: { argb: '757575' }
            }
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
          })

          for(var j=0; j<data.length; j++){
            worksheet.addRow(data[j]);

          }


          worksheet.getColumn(1).width = 40;
          worksheet.getColumn(2).width = 30;
          worksheet.getColumn(3).width = 30;
          worksheet.getColumn(4).width = 30;
          worksheet.getColumn(5).width = 30;
          worksheet.getColumn(6).width = 30;
          worksheet.getColumn(7).width = 40;
          worksheet.addRow([]);


          workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'venteGlobale.xlsx');
          })

        }
      }
    );

  }

  generateExcelMigEntrepot(){
    this.migController.excelentrepot().subscribe(
      (response:any)=>{
        if(response.status == 200){
          let entrepot = response.data;
          let workbook = new Workbook();
          let worksheet = null;

          const header = ["Produit","Entrepot initial","Entrepot final","Quantité"];
          let data = [];

          entrepot.forEach(element => {
            worksheet = workbook.addWorksheet(element.whe_designation);
          });

          workbook.eachSheet(function(worksheet, sheetId) {

            worksheet.addRow([]);
            let headerRow = worksheet.addRow(header);
            headerRow.eachCell((cell, number) => {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF00' },
                bgColor: { argb: '757575' }
              }
              cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
            })


            worksheet.getColumn(1).width = 40;
            worksheet.getColumn(2).width = 30;
            worksheet.getColumn(3).width = 30;
            worksheet.getColumn(4).width = 30;
            worksheet.getColumn(5).width = 30;
            worksheet.addRow([]);


          });

          for(var j=0; j<entrepot.length; j++){
            data = [];
            worksheet = workbook.getWorksheet(entrepot[j].whe_designation);

            for(var i=0; i < entrepot[j].mig.length; i++){
              data[i]= [
                entrepot[j].mig[i].whp_designation,
                entrepot[j].mig[i].whm_codentrepot_initial,
                entrepot[j].mig[i].whm_codentrepot_final,
                entrepot[j].mig[i].whm_quantite,

              ];
            }

            for(var j=0; j<data.length; j++){
              worksheet.addRow(data[j]);

            }
          }

          workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'migration_par_entrepot.xlsx');
          })



        }
      }

    )

  }

  generateExcelMig(){
    this.migController.excelglobale().subscribe(
      (response:any)=>{
        if(response.status == 200){
          const header = ["Produit","Entrepot initial","Entrepot final","Quantité"];
          const title = 'Migration globale';
          let data = [];
          const app = response.data;

          for(var i=0; i < app.length; i++){
            data[i]= [
              app[i].whp_designation,
              app[i].whm_codentrepot_initial,
              app[i].whm_codentrepot_final,
              app[i].whm_quantite,

            ];
          }

          console.log(data);

          let workbook = new Workbook();
          let worksheet = workbook.addWorksheet('Migrations');

          let titleRow = worksheet.addRow([title]);
          titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true }
          worksheet.addRow([]);

          let headerRow = worksheet.addRow(header);

          headerRow.eachCell((cell, number) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFFFF00' },
              bgColor: { argb: '757575' }
            }
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
          })

          for(var j=0; j<data.length; j++){
            worksheet.addRow(data[j]);

          }

          worksheet.getColumn(1).width = 40;
          worksheet.getColumn(2).width = 30;
          worksheet.getColumn(3).width = 30;
          worksheet.getColumn(4).width = 30;
          worksheet.getColumn(5).width = 30;
          worksheet.addRow([]);


          workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'Migration_Globale.xlsx');
          })


        }
      }

    )
  }

  generateExcelApEntrepot(){
    this.apController.excelentrepot().subscribe(
      (response:any)=>{
        if(response.status == 200){
          let entrepot = response.data;
          let workbook = new Workbook();
          let worksheet = null;

          const header = ["Code","Date","Fournisseur", "Produit","Quantité"];
          let data = [];

          entrepot.forEach(element => {
            worksheet = workbook.addWorksheet(element.whe_designation);
          });

          workbook.eachSheet(function(worksheet, sheetId) {

            worksheet.addRow([]);
            let headerRow = worksheet.addRow(header);
            headerRow.eachCell((cell, number) => {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF00' },
                bgColor: { argb: '757575' }
              }
              cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
            })


            worksheet.getColumn(1).width = 40;
            worksheet.getColumn(2).width = 30;
            worksheet.getColumn(3).width = 30;
            worksheet.getColumn(4).width = 30;
            worksheet.getColumn(5).width = 30;
            worksheet.getColumn(6).width = 30;
            worksheet.addRow([]);


          });

          for(var j=0; j<entrepot.length; j++){
            data = [];
            worksheet = workbook.getWorksheet(entrepot[j].whe_designation);

            for(var i=0; i < entrepot[j].ap.length; i++){
              data[i]= [
                entrepot[j].ap[i].wha_code,
                entrepot[j].ap[i].wha_date,
                entrepot[j].ap[i].whf_nom,
                entrepot[j].ap[i].whp_designation,
                entrepot[j].ap[i].wha_quantite,

              ];
            }

            for(var j=0; j<data.length; j++){
              worksheet.addRow(data[j]);

            }

          }

          workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'approvisionnement_par_entrepot.xlsx');
          })


        }
      }

    )
  }

  generateExcelap(){
    this.apController.excelglobale().subscribe(
      (response:any)=>{
        if(response.status == 200){
          const header = ["Code","Date","Entrepot","Fournisseur", "Produit","Quantité"];
          const title = 'Approvisionnement globale';
          let data = [];
          const app = response.data;

          for(var i=0; i < app.length; i++){
            data[i]= [
              app[i].wha_code,
              app[i].wha_date,
              app[i].whe_designation,
              app[i].whf_nom,
              app[i].whp_designation,
              app[i].wha_quantite,

            ];
          }

          console.log(data);

          let workbook = new Workbook();
          let worksheet = workbook.addWorksheet('Approvisionnement');

          let titleRow = worksheet.addRow([title]);
          titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true }
          worksheet.addRow([]);

          let headerRow = worksheet.addRow(header);

          headerRow.eachCell((cell, number) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFFFF00' },
              bgColor: { argb: '757575' }
            }
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
          })

          for(var j=0; j<data.length; j++){
            worksheet.addRow(data[j]);

          }

          worksheet.getColumn(1).width = 40;
          worksheet.getColumn(2).width = 30;
          worksheet.getColumn(3).width = 30;
          worksheet.getColumn(4).width = 30;
          worksheet.getColumn(5).width = 30;
          worksheet.getColumn(6).width = 30;
          worksheet.addRow([]);

          workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'ApprovissionnementGlobale.xlsx');
          })


        }
      }

    )
  }



  generateExcelStockEntrepot(entrepot:any){

    this.stockController.list({modelEnt : entrepot}).subscribe(
      (response:any)=>{
        if(response.status == 200){
          const header = ["Désignation","Quantité initial","Quantité"];
          const title = 'Point de l\'entrepot '+response.data.entrepot;
          let data = [];
          const app = response.data.prod;

          for(var i=0; i < app.length; i++){
            data[i]= [
              app[i].whp_designation,
              app[i].whs_qte_initial,
              app[i].whs_qte,
            ];
          }

          let workbook = new Workbook();
          let worksheet = workbook.addWorksheet('Point de l\'entrepot'+response.entrepot);

          let titleRow = worksheet.addRow([title]);
          titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true }
          worksheet.addRow([]);

          let headerRow = worksheet.addRow(header);

          headerRow.eachCell((cell, number) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFFFF00' },
              bgColor: { argb: '757575' }
            }
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
          })

          for(var j=0; j<data.length; j++){
            worksheet.addRow(data[j]);

          }

          worksheet.getColumn(1).width = 40;
          worksheet.getColumn(2).width = 30;
          worksheet.getColumn(2).width = 30;
          worksheet.addRow([]);

          workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'Point_de_l\'entrepot_'+response.data.entrepot+'.xlsx');
          })

        }
      }
    )

  }


}

