import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FournisseurModel {
  public whf_nom : string;
  public whf_email : string;
  public whf_ville : string;
  public whf_postal : string;
  public whf_code : string;
  public whf_tel : string;
  public whf_adresse : string;
  public whf_ncontribuable : string;
  public whf_description : string;


}
