import { Component, OnInit } from '@angular/core';
import {ProblemsService} from "../../../database/controller/problems.service";
import {EmployeController} from "../../../database/controller/employe.service";
import {ProblemsCrt} from "../../../database/model/problems.service";
import { ProjectctrlService } from 'src/app/database/controller/projectctrl.service';
import { TachemodelService } from 'src/app/database/model/tachemodel.service';
import { TachectrlService } from 'src/app/database/controller/tachectrl.service';
import { DepenseService } from 'src/app/database/controller/depense.service';
import { DonneesousactService } from 'src/app/database/controller/donneesousact.service';
declare let $ : any;
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.css'],
  providers: [DatePipe]
})
export class IssuesComponent implements OnInit {

  public problems1 : any =[];
  public etapeTabsId : any = [];
  public agent : any= [];
  public projetTabs : any = [];
  public activityTabs : any = [];
  public sousactivityTabs : any = [];
  public tabs : any = [];

  public projet : any = null;
  public etape : any = null;
  public activite : any = null;

  constructor(
    public controller : ProblemsService,
    public model : ProblemsCrt,
    public tacheModel : TachemodelService,
    public projetController: ProjectctrlService,
    public tacheController : TachectrlService,
    public depenseController : DepenseService,
    public sousActController : DonneesousactService,
    public employercontroller : EmployeController,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.projetGet();
    this.supervisseurGet();
  }



  monitEtapes(){
    this.depenseController.list_activity().subscribe(
      (response:any) =>{
        this.etape = response.etape;
        this.activityTabs = response.activites;
      }
    )
  }

  modal(param : any){
    this.model.prob_proj_id= param.prob_proj_id;
    this.model.prob_etap_id= param.prob_etap_id;
    this.model.prob_id = param.prob_id;

    if(param.prob_evolution == 0){
      $('#myModal').modal('show');
    }else{
      alert("Cette tache ne peut plus etre mise à jour");
    }
  }


  assignTo(){
    if(this.model.update == 2){
      this.model.prob_assigne = JSON.parse(localStorage.getItem('monitoring_storage')).user_id;

    }
    this.controller.update().subscribe(
      (response: any) => {
        if(response.status == 200){
          alert('Mise à jour effectué avec succés');
          this.issuesGet();
        }

      },
      (error)=>{
        console.log(error);
      }
    )
  }

  monitProjet(){
    this.model.prob_proj_id = this.tacheModel.tache_proj_id;
    this.tacheController.projet_tache().subscribe(
      (response:any) =>{
        this.projet = response.projet;
        this.etapeTabsId = response.etapes;
      }
    )
  }
  projetGet(){
    this.projetController.get().subscribe(
      (response: any) => {
        this.projetTabs = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  issuesAdd(){
    this.model.prob_evolution = 0;
    this.model.prob_date = new Date(this.model.prob_date);
    this.model.prob_date = this.datePipe.transform(this.model.prob_date, 'dd/MM/yyyy');

    this.controller.add().subscribe(
      (response:any) =>{
        alert('Le probléme a bien été enregistré');
        this.model.prob_assigne = "";
        this.model.prob_date = "";
        this.model.prob_date_resolu = "";
        this.model.prob_declarer = "";
        this.model.prob_depense = "";
        this.model.prob_evolution = 0;
        this.model.prob_libelle = "";
        this.model.prob_etap_id = "";
      },
      (error)=>{
        alert('Une erreur est survenue. veuillez vous reconnecter plustard');
        console.log(error);
      }
    )
  }

  issuesGet(){
    this.controller.get(1).subscribe(
      (response:any) =>{
        if(response.status == 200){
          this.problems1 = response.data;
        }
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  supervisseurGet(){
    this.employercontroller.get().subscribe(
      (response: any) => {
        this.tabs = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }





}
