import { Component, OnInit } from '@angular/core';
import { CategorieService } from 'src/app/database/controller/wh/categorie.service';
import { CategorieModel } from 'src/app/database/model/wh/categorie.service';
declare let $ : any;

@Component({
  selector: 'app-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.css']
})
export class CategorieComponent implements OnInit {
  public tabs : any = [];
  public prodTabs : any = null;
  public messageUpt : boolean = false;
  public local_value : any ;

  constructor(
    public controller : CategorieService,
    public model : CategorieModel
  ) { }

  ngOnInit(): void {
    this.local_value = JSON.parse(localStorage.getItem('monitoring_storage'));
  }

  vider(){
    this.model.whc_description = "";
    this.model.whc_designation = "";
  }

  categorieAdd(){
    if(!this.messageUpt){
      this.controller.add().subscribe(
        (response:any) =>{
          alert('La catégorie a bien été enregistré');
          this.vider();
        },
      )
    }else{
      this.controller.update(this.model.whc_codecat).subscribe(
        (response:any) =>{
          alert('Opération réussie')
          this.vider();
        },
      )
    }

  }

  categorieList(){
    this.controller.get().subscribe(
      (response:any) =>{
        if(response.status == 200){
          this.tabs = response.data;
        }

      },
    )
  }

  updateCat(data: any){
    this.messageUpt = true;
    $('#home-tab').tab('show');
    this.model.whc_description = data['whc_description'];
    this.model.whc_designation = data['whc_designation'];
    this.model.whc_codecat = data['idcat'];

  }

  catProduit(code){
    let table = {
      'codecat':code
    };

    this.controller.produit(table).subscribe(
      (response:any) =>{
        if(response.status == 200){
          this.prodTabs = response.data;
        }

      },
    )
    $('#myModal').modal('show');
  }
}
