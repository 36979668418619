import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OutilModel {
  public 	who_desig : string;
  public  who_cat_code : string;
  public  who_type_usage : string;

  public  user : string;
  public  date : any;
  public  qte : any;
  public  etat : string;

  public whso_code_projet:any;
  public whso_code_sup:any;



}
