import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/database/controller/categories.service';
import { EtapesService } from 'src/app/database/controller/etapes.service';
import { EtapeDailModel } from 'src/app/database/model/etapdailmodel.service';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.css']
})
export class StepsComponent implements OnInit {
  public tabs : any = [];
  public cattabs : any = [];

  constructor(
    public controler : EtapesService,
    public model: EtapeDailModel,
    public catcontroler : CategoriesService,
  ) { }

  ngOnInit(): void {
    this.categorieGet();
  }

  stepsAdd(){
    this.controler.add().subscribe(
      (response:any) =>{
        alert('L\'etapes a bien été enregistré');
        this.model.etap_desc = "";
        this.model.etap_libelle = "";
      },
      (error)=>{
        alert('Une erreur est survenue. veuillez vous reconnecter plustard');
        console.log(error);
      }
    )
  }

  deleteEtape(id){
    if(!window.confirm("Etes vous sur de bien vouloir supprimer cette étape?")) return;
    this.controler.delete(id).subscribe(
      (response) => {
        this.categorieGet();
        this.stepsGet();
        console.log(response);
      },
      (error) => {
        console.log(error)
      }
    )
  }

  categorieGet(){
    this.catcontroler.get().subscribe(
      (response: any) => {
        if(response.status == 200){
          this.cattabs = response.data;
        }
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  stepsGet(){
    this.controler.get().subscribe(
      (response: any) => {
        this.tabs = response.data;
      },
      (error)=>{
        console.log(error);
      }
    )
  }

}
