import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DepensemodelService {
  public depense_libel : string;
  public depense_date : string;
  public depense_mont : string;
  public depense_act_id : string;
  public depense_projet_id : string;
  public depense_etap_id : string;
  public depense_commentaire : string;

  constructor() { }
}
