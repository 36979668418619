import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';
import { FournisseurModel } from '../../model/wh/fournisseur.service';

@Injectable({
  providedIn: 'root'
})
export class FournisseurService {

  constructor(
    public http: HttpClient,
    public model: FournisseurModel
  ) { }

  public add(){
    return this.http.post(_SERVEROOT.fournisseurs_post,this.model);
  }

  public get(){
    return this.http.get(_SERVEROOT.fournisseurs_get);
  }

  public update(idfour:any){
    return this.http.put(_SERVEROOT.fournisseurs_update+idfour,this.model);
  }
}
