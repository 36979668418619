import { Component, OnInit } from '@angular/core';
import { EmployeController } from 'src/app/database/controller/employe.service';
import { ProjectctrlService } from 'src/app/database/controller/projectctrl.service';
import { CategorieService } from 'src/app/database/controller/wh/categorie.service';
import { OutilsService } from 'src/app/database/controller/wh/outils.service';
import { CategorieModel } from 'src/app/database/model/wh/categorie.service';
import { OutilModel } from 'src/app/database/model/wh/outil.service';
declare let $ : any;

@Component({
  selector: 'app-whoutils',
  templateUrl: './whoutils.component.html',
  styleUrls: ['./whoutils.component.css']
})
export class WhoutilsComponent implements OnInit {

  public page: number = 0;
  public pageSortie: number = 0;
  public tabs : any = [];
  public tabsProj : any = [];
  public tabsSup : any = [];
  public tabsEnt : any = [];
  public tabsProdCat : any = [];
  public tabsOutils : any = [];
  public tabsOutilsSorti : any = [];
  public tabsOutilsSort : any = [];
  public tabsDetailModal : any = null;
  public prodTabs : any = null;
  public messageUpt : boolean = false;
  public local_value : any ;
  public valSortieForm:any = [];

  constructor(
    public categorieModel : CategorieModel,
    public outilModel : OutilModel,
    public categorieService : CategorieService,
    public outilsService : OutilsService,
    public projectctrlService : ProjectctrlService,
    public employeController : EmployeController
  ) { }

  ngOnInit(): void {
    this.local_value = JSON.parse(localStorage.getItem('monitoring_storage'));
    this.pageSortie =  0;

  }

  vider(){
    this.categorieModel.whc_description = "";
    this.categorieModel.whc_designation = "";
  }

  categorieAdd(){
    this.categorieModel.whc_type = 'outils';
    if(!this.messageUpt){
      this.categorieService.add().subscribe(
        (response:any) =>{
          alert('La catégorie d\'outil a bien été enregistré');
          this.vider();
        },
      )
    }else{
      this.categorieService.update(this.categorieModel.whc_codecat).subscribe(
        (response:any) =>{
          alert('Opération réussie')
          this.vider();
        },
      )
    }

  }

  categorieList(){
    this.outilsService.listoutilscat().subscribe(
      (response:any) =>{
        if(response.status == 200){
          this.tabs = response.data;
        }
      },
    )
  }

  updateCat(data: any){
    this.messageUpt = true;
    $('#home-tab').tab('show');
    this.categorieModel.whc_description = data['whc_description'];
    this.categorieModel.whc_designation = data['whc_designation'];
    this.categorieModel.whc_codecat = data['idcat'];

  }

  createOutils(){
    this.outilModel.date = "10/01/2021";
    this.outilModel.user = this.local_value.user_name;
    this.outilsService.create().subscribe(
      (response:any) =>{
        if(response.status == 200){
          alert('L\'outils a bien été enregistré.')
        }
      },
    )
  }

  public ajouter(table:any){
    this.valSortieForm.push({who_code:table.who_code, who_desig:table.who_desig, whs_code:table.whs_code, whs_qte:table.whs_qte,qte:this.outilModel.qte});
  }

  public supprimer(id:number){
    this.valSortieForm.splice(id,1);
  }

  listProduitCat(catCode){
    let table = {
      'cat' : catCode
    };

    this.outilsService.listCatProduit(table).subscribe(
      (response:any) =>{
        if(response.status == 200){
          this.tabsProdCat = response.data;
          $('#myModal').modal('show');
          console.log(response)
        }
      },
    )
  }

  modalSortie(){
    this.listProjetSup();
    $('#exampleModal').modal('show');
  }

  ListOutils(){
    this.outilsService.list().subscribe(
      (response:any) =>{
        if(response.status == 200){
          this.tabsOutils = response.data;
        }
      },
    )
  }

  sortieList(){
    this.outilModel.qte = null;
    this.outilModel.date = null;

    this.outilsService.listSortie().subscribe(
      (response:any) =>{
        if(response.status == 200){
          this.tabsOutilsSorti = response.data;
        }
      }
    )
  }


  public createEnt(codeStock:any,qte_initial:any,codeproduit:any,qte_rest:any){
    let table = {
      'codeproduit'   : codeproduit,
      'user'          : this.local_value.user_name,
      'qte'           : this.outilModel.qte,
      'date'          : this.outilModel.date,
      'qte_initial'   : qte_initial,
      'stock'         : codeStock,
      'qte_rest'      : qte_rest
    };


    this.outilsService.createEntree(table).subscribe(
      (response:any) =>{
        if(response.status == 200){
          alert('Opération effectué');
          this.ListOutils();
        }
      },
    )
  }

  public listEnt(){
    this.outilsService.listEntree().subscribe(
      (response:any) =>{
        if(response.status == 200){
          console.log(response)
          this.tabsEnt = response.data;
        }
      },
    )
  }

  public createSortie(){
    let table = {
      table : this.valSortieForm,
      whso_date_outil :this.outilModel.date,
      whso_code_projet :this.outilModel.whso_code_projet,
      whso_code_sup :this.outilModel.whso_code_sup,

    }

    this.outilsService.createSortie(table).subscribe(
      (response:any) =>{
        if(response.status == 200){
          console.log(response)
          alert('Opération réussi')
          $('#exampleModal').modal('hide');
          this.outilModel.date = null;
          this.outilModel.whso_code_projet = null;
          this.outilModel.whso_code_sup = null;
          this.valSortieForm = [];
        }
      },
    )
  }

  public listProjetSup(){
    this.projectctrlService.get().subscribe(
      (response:any) =>{
        console.log(response)
        this.tabsProj = response;

      },
    )

    this.employeController.get().subscribe(
      (response:any) =>{
        console.log(response)
        this.tabsSup = response;

      },
    )

  }

  public listSortie(){
    this.outilsService.sortieList().subscribe(
      (response:any) =>{
        console.log(response)
        if(response.status == 200){
          this.tabsOutilsSort = response.data
        }
      },
    )
  }

  public sortieModal(detail:any){
    this.tabsDetailModal = detail;
    this.outilModel.date = null;
    this.outilModel.qte = null;
    this.outilModel.etat = null;
    $('#exampleMod').modal('show');
  }

  public createRetour(code_so:string , code_outils:string){
    let table = {
      'whr_code_whso'     : code_so,
      'whr_code_outils'   : code_outils,
      'whr_date'          : this.outilModel.date,
      'whr_qte'           : this.outilModel.qte,
      'whr_etat_produit'  : this.outilModel.etat
    };

    this.outilsService.retouroutils(table).subscribe(
      (response:any) =>{
        console.log(response)
        if(response.status == 200){
          alert('Opération réussie')
          $('#exampleMod').modal('hide');
        }
      },
    )
  }




}
