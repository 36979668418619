
export const INITIAL = {
    /* link: "http://monitoring.server.asfaserverapp.com/public/",
    link_api: "http://monitoring.server.asfaserverapp.com/public/api/monitoring/", */

    link: "https://monitoring.server.asfaserverapp.com/public/",
    link_api: "https://monitoring.server.asfaserverapp.com/public/monitoring/",
    link_api_wah: "https://monitoring.server.asfaserverapp.com/public/warehouse/"

    // link: "http://localhost:8000/",
    // link_api: "http://localhost:8000/monitoring/",
    // link_api_wah: "http://localhost:8000/warehouse/" 

};

export const _SERVEROOT: any = {
    /*
     email
     password
     method => POST
    */
    login : INITIAL.link+'auth/login',
    user: INITIAL.link +'users',
    update_pwd : INITIAL.link+'api/password/reset',
    user_get_monit : INITIAL.link+'users/monitoring',


    //categories
    categories_get : INITIAL.link_api+'categories',
    categories_post : INITIAL.link_api+'categories',
    categories_get_id : INITIAL.link_api+'categories',
    categories_update : INITIAL.link_api+'categories',
    categories_delete: INITIAL.link_api+'categories',


    //activites
    activites_get : INITIAL.link_api+'activites/get',
    activites_post : INITIAL.link_api+'activites',
    activites_get_id : INITIAL.link_api+'activites',
    activites_update : INITIAL.link_api+'activites',
    activites_delete : INITIAL.link_api+'activites',


    //etapes
    etapes_get : INITIAL.link_api+'etapes',
    etapes_post : INITIAL.link_api+'etapes',
    etapes_get_id : INITIAL.link_api+'etapes',
    etapes_update : INITIAL.link_api+'etapes',
    etapes_delete : INITIAL.link_api,


    //problemes
    problemes_get : INITIAL.link_api+'problemes/list',
    problemes_post : INITIAL.link_api+'problemes',
    problemes_get_id : INITIAL.link_api+'problemes',
    problemes_update : INITIAL.link_api+'problemes/update',
    problemes_delete : INITIAL.link_api+'problemes',


    //projets
    projets_get : INITIAL.link_api+'projets',
    projets_get_pays : INITIAL.link_api+'projets/pays',
    projets_post : INITIAL.link_api+'projets',
    projets_get_id : INITIAL.link_api+'projets/proj_id',
    projets_detail : INITIAL.link_api+'projets/details',
    projets_update : INITIAL.link_api+'projets',
    projets_delete : INITIAL.link_api,

    //devis
    devis_get : INITIAL.link_api+'devis',
    devis_post : INITIAL.link_api+'devis',
    devis_get_id : INITIAL.link_api+'devis',
    devis_update : INITIAL.link_api+'devis',
    devis_delete : INITIAL.link_api+'devis',

    //serverapp
    devis_demande : INITIAL.link_api+'devis/demande',


    //taches
    taches_get : INITIAL.link_api+'taches',
    taches_projet_get_id : INITIAL.link_api+'taches/projet',
    taches_etape_get_id : INITIAL.link_api+'taches/etape',
    taches_post : INITIAL.link_api+'taches',
    taches_get_id : INITIAL.link_api+'taches',
    taches_update : INITIAL.link_api+'taches',
    taches_delete : INITIAL.link_api+'taches',


    //sousActivites
    sousActivites_get : INITIAL.link_api+'sousActivites',
    sousActivites_post : INITIAL.link_api+'sousActivites',
    sousActivites_get_id : INITIAL.link_api+'sousActivites',
    sousActivites_update : INITIAL.link_api+'sousActivites',
    sousActivites_delete : INITIAL.link_api+'sousActivites',


    //materiels
    materiels_get : INITIAL.link_api+'materiels',
    materiels_post : INITIAL.link_api+'materiels',
    materiels_get_id : INITIAL.link_api+'materiels',
    materiels_update : INITIAL.link_api+'materiels',
    materiels_delete : INITIAL.link_api+'materiels',


    //users
    users_get : INITIAL.link_api+'users',
    users_post : INITIAL.link_api+'users',
    users_get_id : INITIAL.link_api+'users',
    users_update : INITIAL.link_api+'users',
    users_delete : INITIAL.link_api+'users',

    //supervisseur
    superviseurs_get : INITIAL.link_api+'superviseurs',
    techniciens_get : INITIAL.link_api+'technicien',
    superviseurs_post : INITIAL.link_api+'superviseurs',
    superviseurs_get_id : INITIAL.link_api+'superviseurs',
    superviseurs_update : INITIAL.link_api+'superviseurs',
    superviseurs_delete : INITIAL.link_api+'superviseurs',

    //fichiers
    fichiers_get : INITIAL.link_api+'fichiers',
    fichiers_post : INITIAL.link_api+'fichiers',
    affiche_photo_post : INITIAL.link_api+'photo',
    fichiers_post_photo : INITIAL.link_api+'fichiers/photo',
    fichiers_list : INITIAL.link_api+'fichiers/list',
    fichiers_get_id : INITIAL.link_api+'fichiers',
    fichiers_update : INITIAL.link_api+'fichiers',
    fichiers_delete : INITIAL.link_api+'fichiers',
    fichiers_affich : 'https://monitoring.server.asfaserverapp.com/public/file/read',
    fichiers_photo: INITIAL.link_api+'file/read',


    //depenses
    depenses_get : INITIAL.link_api+'depenses/listes',
    depenses_post : INITIAL.link_api+'depenses',
    depenses_list : INITIAL.link_api+'depenses/list',
    depenses_activity : INITIAL.link_api+'depenses/activity',
    depenses_get_id : INITIAL.link_api+'depenses',
    depenses_update : INITIAL.link_api+'depenses',
    depenses_delete : INITIAL.link_api+'depenses/sup',

    //donnees
    donnees_get : INITIAL.link_api+'donnees',
    donnees_post : INITIAL.link_api+'donnees',
    donnees_list : INITIAL.link_api+'donnees/list',
    donnees_get_id : INITIAL.link_api+'donnees',
    donnees_update : INITIAL.link_api+'donnees',
    donnees_delete : INITIAL.link_api+'donnees',


    //outils
    outils_list : INITIAL.link_api+'outils',




    //warehouse route

    //whpprovisonnements
    whpprovisonnements_get : INITIAL.link_api_wah+'whpprovisonnements',
    whpprovisonnements_post : INITIAL.link_api_wah+'whpprovisonnements',
    whpprovisonnements_get_id : INITIAL.link_api_wah+'whpprovisonnements',
    whpprovisonnements_update : INITIAL.link_api_wah+'whpprovisonnements',
    whpprovisonnements_delete : INITIAL.link_api_wah+'whpprovisonnements',
    whpprovisonnements_export_globale : INITIAL.link_api_wah+'whpprovisonnements/export/globale',
    whpprovisonnements_export_entrepot : INITIAL.link_api_wah+'whpprovisonnements/export/entrepot',

    //whcategories
    whcategories_get : INITIAL.link_api_wah+'whcategories',
    whcategories_post : INITIAL.link_api_wah+'whcategories',
    whcategories_produit : INITIAL.link_api_wah+'whcategories/produit',
    whcategories_get_id : INITIAL.link_api_wah+'whcategories',
    whcategories_update : INITIAL.link_api_wah+'whcategories/',
    whcategories_delete : INITIAL.link_api_wah+'whcategories',

    //whclientslink_api_wah
    whclients_get : INITIAL.link_api_wah+'whclients',
    whclients_post : INITIAL.link_api_wah+'whclients',
    whclients_get_id : INITIAL.link_api_wah+'whclients',
    whclients_update : INITIAL.link_api_wah+'whclients',
    whclients_delete : INITIAL.link_api_wah+'whclients',

    //whentrepots
    whentrepots_get : INITIAL.link_api_wah+'whentrepots',
    whentrepots_post : INITIAL.link_api_wah+'whentrepots',
    whentrepots_get_id : INITIAL.link_api_wah+'whentrepots',
    whentrepots_update : INITIAL.link_api_wah+'whentrepots/',
    whentrepots_delete : INITIAL.link_api_wah+'whentrepots',


    //fournisseurs
    fournisseurs_get : INITIAL.link_api_wah+'whfournisseurs',
    fournisseurs_post : INITIAL.link_api_wah+'whfournisseurs',
    fournisseurs_get_id : INITIAL.link_api_wah+'whfournisseurs',
    fournisseurs_update : INITIAL.link_api_wah+'whfournisseurs/',
    fournisseurs_delete : INITIAL.link_api_wah+'whfournisseurs',

    //whmigrations
    //whmigrations_get : INITIAL.link_api_wah+'whmigrations',
    whmigrations_post : INITIAL.link_api_wah+'whmigrations',
    whmigrations_kit : INITIAL.link_api_wah+'kit/whmigrations',
    whmigrations_get : INITIAL.link_api_wah+'whmigrations',
    whmigrations_export_globale : INITIAL.link_api_wah+'whmigrations/export/globale',
    whmigrations_export_entrepot : INITIAL.link_api_wah+'whmigrations/export/entrepot',
    /* whmigrations_get_id : INITIAL.link_api_wah+'whmigrations',
    whmigrations_update : INITIAL.link_api_wah+'whmigrations',
    whmigrations_delete : INITIAL.link_api_wah+'whmigrations', */

    //whproduits
    whproduits_list : INITIAL.link_api_wah+'whproduits/listes',
    whproduits_post : INITIAL.link_api_wah+'whproduits',
    whproduits_get_select : INITIAL.link_api_wah+'whproduits/select',
    whproduits_get_id : INITIAL.link_api_wah+'whproduits',
    whproduits_update : INITIAL.link_api_wah+'whproduits/',
    whproduits_delete : INITIAL.link_api_wah+'whproduits',
    whproduits_search : INITIAL.link_api_wah+'whproduits/search',

    //whstocks
    whstocks_post_list : INITIAL.link_api_wah+'whstocks/entrepot/liste',
    whstocks_post_ante_an : INITIAL.link_api_wah+'whstocks/vente/ancien',
    whstocks_post_new_vente: INITIAL.link_api_wah+'whstocks/vente/new',
    whstocks_vente_ancien: INITIAL.link_api_wah+'/whstocks/vente/ancien',
    whstocks_post_approvionnement : INITIAL.link_api_wah+'whstocks/approvisionnement',
    whstocks_post : INITIAL.link_api_wah+'whstocks',
    whstocks_get_id : INITIAL.link_api_wah+'whstocks',
    whstocks_update : INITIAL.link_api_wah+'whstocks',
    whstocks_delete : INITIAL.link_api_wah+'whstocks',

    //whtypes
    whtypes_get : INITIAL.link_api_wah+'whtypes',
    whtypes_post : INITIAL.link_api_wah+'whtypes',
    whtypes_get_id : INITIAL.link_api_wah+'whtypes',
    whtypes_update : INITIAL.link_api_wah+'whtypes',
    whtypes_delete : INITIAL.link_api_wah+'whtypes',

    whbilan : INITIAL.link_api_wah+'whbilan',
    whbilan_produit : INITIAL.link_api_wah+'whbilan/produit',

    whkit_create : INITIAL.link_api_wah+'kit/create',
    whkit_get : INITIAL.link_api_wah+'kit/liste',
    whkit_vente : INITIAL.link_api_wah+'kit/ancienclient',

    //Devis
    whdevis_get : INITIAL.link_api_wah+'devis/creer',


    //whventes
    whventes_globale : INITIAL.link_api_wah+'whventes/globale',
    whventes_entrepot : INITIAL.link_api_wah+'whventes/entrepots',
    whventes_post : INITIAL.link_api_wah+'whventes',
    /* whventes_get_id : INITIAL.link_api_wah+'whventes',
    whventes_update : INITIAL.link_api_wah+'whventes',
    whventes_delete : INITIAL.link_api_wah+'whventes', */


    //outils
    whoutils_cat : INITIAL.link_api_wah+'outils/whcategories',
    whoutils_post : INITIAL.link_api_wah+'outils',
    whoutils_get : INITIAL.link_api_wah+'outils',
    whoutils_entrees : INITIAL.link_api_wah+'outils/entrees',
    whoutils_entrees_list : INITIAL.link_api_wah+'outils/entrees/list',
    whoutils_sortie_list : INITIAL.link_api_wah+'outils/list/sortie',
    whoutils_sortie_post : INITIAL.link_api_wah+'outils/sorties',
    whoutils_sortie_get : INITIAL.link_api_wah+'outils/sorties/list',
    whoutils_retour : INITIAL.link_api_wah+'outils/retour',


    /* method => GET */
    show : INITIAL.link+'/user/show',
    registerUser : INITIAL.link+'/user/register',
    registerProjet : INITIAL.link+'api/projet/register',
    getProjet : INITIAL.link+'api/projet/get',
    getEmploye : INITIAL.link+'api/agent/get',
    registerEmploye : INITIAL.link+'api/agent/register',
    registerTache : INITIAL.link+'api/taches/register',
    getTache : INITIAL.link+'api/mobile/projet/taches',
    getgroup : INITIAL.link+'api/agent/get',
    getclientGroup: INITIAL.link+'api/mobile/journalier/agents',
    getProblems : INITIAL.link+'api/mobile/issue/get',
    agentAssigne : INITIAL.link+'api/mobile/issue/resolve',
    Updateissue : INITIAL.link+'api/mobile/issue/update',
    getPointage : INITIAL.link+'api/mobile/pointage/get',
    getProgress : INITIAL.link+'api/projet/log',
    getgroupCons : INITIAL.link+'api/agent/groupe',

};




