import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TachemodelService {
  public tache_etap_id : string;
  public tache_proj_id : string;
  public tache_act : any ;
  public tache_priority : string;
  public tache_date_debut ;
  public tache_date_fin ;
  public tache_resp : string;
  public tache_comment : string;

  constructor() { }

  /* public tache_etap_id : string;
  public tache_proj_id : string;
  public tache_priority : string;
  public tache_date_debut : string;
  public tache_date_fin : string;
  public tache_resp : string;
  public tache_comment : string;
  public tache_nbre_main_oeuvre : string; */
}
