import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';

@Injectable({
  providedIn: 'root'
})
export class DevisService {

  constructor(
    public http :HttpClient,
  ) { }

    public listproduit(){
      return this.http.get(_SERVEROOT.devis_demande);
    }

    public createDevis(table:any){
      return this.http.post(_SERVEROOT.devis_post,table);
    }

    public listdevis(){
      return this.http.get(_SERVEROOT.devis_get);
    }





}
