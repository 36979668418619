import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';
import { MigrationModel } from '../../model/wh/migration.service';

@Injectable({
  providedIn: 'root'
})
export class MigrationService {

  constructor(
    public http: HttpClient,
    public model: MigrationModel
  ) { }

  public add(data:any){
    return this.http.post(_SERVEROOT.whmigrations_post,data);
  }

  public kit_add(data:any){
    return this.http.post(_SERVEROOT.whmigrations_kit,data);
  }

  public get(){
    return this.http.get(_SERVEROOT.whmigrations_get);
  }

  public bilan(){
    return this.http.get(_SERVEROOT.whbilan);
  }

  public excelglobale(){
    return this.http.get(_SERVEROOT.whmigrations_export_globale);
  }

  public excelentrepot(){
    return this.http.get(_SERVEROOT.whmigrations_export_entrepot);
  }

  public bilanProduit(){
    return this.http.get(_SERVEROOT.whbilan_produit);
  }

}
