import { Component, OnInit } from '@angular/core';
import { ApprovisionnementService } from 'src/app/database/controller/wh/approvisionnement.service';
import { EntrepotService } from 'src/app/database/controller/wh/entrepot.service';
import { ExcelService } from 'src/app/services/excel.service';


@Component({
  selector: 'app-approvisionnement',
  templateUrl: './approvisionnement.component.html',
  styleUrls: ['./approvisionnement.component.css']
})
export class ApprovisionnementComponent implements OnInit {
  public tableAp:any = [];
  public tableApProduit:any = [];
  public tableApFour:any = [];
  public entrepot:any = [];
  public modelEnt:string;
  public modal:number;
  public tableApEnt:any = [];
  returnedArray: string[];
  returnedArrayvente: string[];
  public tableClient:any = [];
  public datedeb:Date;
  public datef:Date;

  constructor(
    public entrepotController : EntrepotService,
    public approService : ApprovisionnementService,
    private excelService:ExcelService
  ) {
   }

  ngOnInit(): void {
    this.EntrepotList();
    this.modal = 0;
  }

  EntrepotList(){
    this.entrepotController.get().subscribe(
      (response:any)=>{

        this.entrepot = response;
      }
    )
  }

  public listeAp(){
    this.modal = 1;
    this.tableAp = [];
    this.tableApProduit = [];
    this.approService.add({modelEnt:this.modelEnt}).subscribe(
      (response:any)=>{
        if(response.status == 200){
          this.tableAp = response.data.ap;
          this.returnedArray = this.tableAp.slice(0, 10);
        }
      }
    )
  }

  public excelApGlobale(){
    this.excelService.generateExcelap();

  }

  public excelApEntrepot(){
    this.excelService.generateExcelApEntrepot();
  }

}
