import { BrowserModule } from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import {ComponentsModule} from "./components/components.module";
import {ServicesModule} from "./services/services.module";
import {appRoutes} from "./app.routing";
import {RouterModule} from "@angular/router";
import {APP_BASE_HREF} from "@angular/common";
import { UsersComponent } from './users/users.component';
import { StatsComponent } from './stats/stats.component';
import { SettingsComponent } from './settings/settings.component';
import {HomeComponent} from "./home/home.component";
import {SidebarModule} from "./sidebar/sidebar.module";
import {HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import { AppContainerComponent } from './app-container/app-container.component';
import {NgxNotificationComponent} from "ngx-notification";
import {CookieService} from "ngx-cookie-service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DatabaseModule} from "./database/database.module";
import {NotifierModule} from "angular-notifier";
import { NgProgressModule } from 'ngx-progressbar';
import {HomedailyComponent} from "./dailyscoring/homedaily/homedaily.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {DailyscoringModule} from "./dailyscoring/dailyscoring.module";
import { WarehouseModule } from './warehouse/warehouse.module';
import { WarehouseComponent } from './warehouse/component/component.component';
import { InterceptService } from './intercept/intercept.service';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ExcelService } from './services/excel.service';




@NgModule({
  declarations: [
    AppComponent,
    WarehouseComponent,
    UsersComponent,
    StatsComponent,
    SettingsComponent,
    HomeComponent,
    AppContainerComponent,
    DashboardComponent,
    HomedailyComponent,

  ],
  imports: [
    BrowserModule,
    CommonModule,
    ComponentsModule,
    RouterModule.forRoot(appRoutes),
    ServicesModule,
    SidebarModule,
    HttpClientModule,
    FormsModule,
    DatabaseModule,
    NotifierModule,
    DailyscoringModule,
    WarehouseModule,
    NgProgressModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot(),

  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],

  providers: [
    CookieService,
    BsDatepickerConfig,
    ExcelService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService , multi: true },
   /*  {
      provide: APP_BASE_HREF,
      useValue: '/api/serverapp/version/2.1.19'
    } */
  ],
  bootstrap: [AppComponent]

})

export class AppModule { }
