import { Injectable } from '@angular/core';
import {Router, ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {


  constructor(public router : Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (localStorage.getItem('monitoring_storage') && JSON.parse(localStorage.getItem('monitoring_storage')).user_type == 'monitoring'){
      return true
    }
    this.router.navigate(['/login']);
    return false;
  }
}


/* @Injectable()
export class LoginService{

  constructor(public router : Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.cookieService.get('__asfaserv')){
      return true
    }
    this.router.navigate(['/project/view']);
    return undefined;
  }

} */
