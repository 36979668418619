import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ProjectmodelService} from "../model/projectmodel.service";
import {_SERVEROOT} from "../../routes/server-root";
declare let Promise : any;
@Injectable({
  providedIn: 'root'
})
export class ProjectctrlService {
  constructor(
    public http : HttpClient,
    public model : ProjectmodelService ,
  ) { }
  // Methode d'insertion d'Utilisateur
  /* public add (data : ProjectmodelService) {
    let table: any = {
      nom : data.nom,
      superviseur : data.superviseur,
      datestart : data.datestart,
      dateend : data.dateend,
      lieu : data.lieu,
      description : data.description
    };
    return new Promise((resolve, reject) => {
      this.http.post(_SERVEROOT.registerProjet, table)
          .subscribe(
              data => {
                resolve(data);
              },
              err => {
                reject(err);
              }
          );
    });
  }

  public get(){
    return new Promise((resolve, reject) => {
      this.http.get(_SERVEROOT.getProjet)
          .subscribe(
              data => {
                resolve(data);
              },
              err => {
                reject(err);
              }
          );
    });
  } */

  public add(){
    return this.http.post(_SERVEROOT.projets_post,this.model);
  }

  public get(){
    return this.http.get(_SERVEROOT.projets_get);
  }

  public details(){
    return this.http.post(_SERVEROOT.projets_detail,this.model);
  }

  public get_id(){
    return this.http.post(_SERVEROOT.projets_get_id,this.model);
  }

  public update(){
    return this.http.put(_SERVEROOT.projets_update,this.model);
  }

  public pays(){
    return this.http.get(_SERVEROOT.projets_get_pays);
  }

  public delete(id){
    return this.http.delete(_SERVEROOT.projets_delete+ 'projets/'+ id);
  }
}
