import { Component, OnInit } from '@angular/core';
import { EntrepotService } from 'src/app/database/controller/wh/entrepot.service';
import { VentesService } from 'src/app/database/controller/wh/ventes.service';
import { ExcelService } from 'src/app/services/excel.service';
declare let $ : any;

@Component({
  selector: 'app-ventes',
  templateUrl: './ventes.component.html',
  styleUrls: ['./ventes.component.css']
})
export class VentesComponent implements OnInit {
  public modelEnt:string;
  public entrepot:any = [];
  public tableVente : any = [];
  public tableProduit : any = [];
  public modal : number = 0;
  public returnedArrayvente : any = [];
  public detailTab : any = null;
  public nom : any = null;
  public date : any = null;



  constructor(
    public entrepotController : EntrepotService,
    public venteController: VentesService,
    private excelService:ExcelService

  ) { }

  ngOnInit(): void {
    this.EntrepotList();
  }

  EntrepotList(){
    this.entrepotController.get().subscribe(
      (response:any)=>{

        this.entrepot = response;
      }
    )
  }

  public get(){
    this.tableVente = [];
    this.tableProduit = [];
    this.modal = 3;
    this.venteController.add({modelEnt:this.modelEnt}).subscribe(
      (response:any)=>{
        if(response.status == 200){
          this.tableVente = response.data.vente;
          this.returnedArrayvente = this.tableVente.slice(0, 10);
        }
      }
    )
  }

  public voirDetVent(details:any,date:any,nom:any,lgModal: any){
    this.detailTab=details;
    $('#myModal').modal('show');
    this.nom = nom;
    this.date = date;

  }

  excelventeglobale():void{
    this.excelService.generateExcel();

    //this.excelService.exportAsExcelFile(this.dataOfFootballers, 'footballer_data');
    /* this.venteController.excelglobale().subscribe(
      (response:any)=>{
        alert(response);
      }
    ) */
  }

  public excelventeentrepot(){
    this.excelService.generateExcelVenteEntrepot();
    //this.venteController.excelentrepot;
  }

}
