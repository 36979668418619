import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _SERVEROOT } from 'src/app/routes/server-root';
import { FichiermodelService } from '../model/fichiermodel.service';

@Injectable({
  providedIn: 'root'
})
export class FichierService {

  constructor(
    public http :HttpClient,
    public model : FichiermodelService
  ) { }

  public add(table:any){
    return this.http.post(_SERVEROOT.fichiers_post,table);
  }

  public photo(table:any){
    return this.http.post(_SERVEROOT.fichiers_post_photo,table);
  }

  public get(){
    return this.http.get(_SERVEROOT.fichiers_get);
  }

  public list(id){
    return this.http.post(_SERVEROOT.fichiers_list,{'actiModel' : id});
  }

  public get_id(){
    return this.http.get(_SERVEROOT.fichiers_get_id);
  }

  public affichephoto(img:any){
    return this.http.post(_SERVEROOT.fichiers_affich,{'image':img});
  }


  public update(){
    return this.http.put(_SERVEROOT.fichiers_update,this.model);
  }

  public delete(){
    return this.http.delete(_SERVEROOT.fichiers_delete);
  }

  public affiche_post_photo(lien) {
    return this.http.post(_SERVEROOT.affiche_photo_post, lien);
  }
}
