import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProblemsCrt {
  public prob_id :string;
  public prob_libelle :string;
  public prob_date ;
  public prob_declarer :string;
  public prob_act_id: string;
  public prob_proj_id: string;
  public prob_etap_id: string;
  public prob_assigne:string;
  public prob_evolution:number;
  public prob_depense:string;
  public prob_motif_rejet:string;
  public prob_date_resolu:string;
  public update:number;
  constructor() { }

}
