import { Component, OnInit } from '@angular/core';
import {PointageService} from "../../../database/controller/pointage.service";
import {ProjectctrlService} from "../../../database/controller/projectctrl.service";
import { PointService } from 'src/app/database/model/pointage.service';

@Component({
  selector: 'app-scoring',
  templateUrl: './scoring.component.html',
  styleUrls: ['./scoring.component.css']
})
export class ScoringComponent implements OnInit {
  public Tpointage : any =[];
  public tab :any = [];
  public paie:string;
  constructor(public pointageModel:PointageService,
    public projetController : ProjectctrlService,
    public Codpoint :PointService) { }

  ngOnInit() {
  }
  /* public get(){
    this.projetController.get()
        .then((data) => {
          this.tab = data.data;
        })
        .catch((err) => {});
  } */

public getPoint(value){
  this.pointageModel.getPointag(value)
  .then((dataPointage) => {
    this.Tpointage=dataPointage.data;
    let filter: any=[];
    filter=dataPointage.data.filter((value)=>{return value.pointage.paie});

    for(let data of filter){
      this.paie=data;
    }

  })
  .catch((err) =>{
    alert("une erreur est survenue,veuillez réessayer");
  })
}

public add(code){

}
}
