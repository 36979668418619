import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DonneesousactmodelService {

  public donnesact_comment : string;
  public donnesact_priorite : string;
  public donnesact_assigne : string;
  public donnesact_date_debut : string;
  public donnesact_date_fin : string;
  public donnesact_sous_activite_id : string;

  constructor() { }
}
