import { Component, OnInit } from '@angular/core';
import { FournisseurService } from 'src/app/database/controller/wh/fournisseur.service';
import { FournisseurModel } from 'src/app/database/model/wh/fournisseur.service';
declare let $ : any;

@Component({
  selector: 'app-fournisseurs',
  templateUrl: './fournisseurs.component.html',
  styleUrls: ['./fournisseurs.component.css']
})
export class FournisseursComponent implements OnInit {
  public tabs : any = [];
  public messageUpt : boolean = false;
  public local_value : any ;

  constructor(
    public controller : FournisseurService,
    public model : FournisseurModel
  ) { }

  ngOnInit(): void {
    this.local_value = JSON.parse(localStorage.getItem('monitoring_storage'));
  }

  viderModel(){
    this.model.whf_nom = "";
    this.model.whf_email = "";
    this.model.whf_tel = "";
    this.model.whf_ville = "";
    this.model.whf_adresse = "";
    this.model.whf_postal = "";
    this.model.whf_description = "";
    this.model.whf_ncontribuable = "";
  }

  fournisseurAdd(){
    if(this.messageUpt){
      this.controller.update(this.model.whf_code).subscribe(
        (response : any) => {
            alert('Opération réussie')
            this.messageUpt = false;
            this.viderModel();

        }
      )
    }else{
      this.controller.add().subscribe(
        (response:any) =>{
          alert('Le fournisseur a bien été enregistré');

          this.viderModel();
        },
      )
    }

  }

  fournisseurList(){
    this.controller.get().subscribe(
      (response: any) =>{
        this.tabs = response;
      }
    )
  }

  update(data: any){
    this.model.whf_code = data['idfourn'];
    this.messageUpt = true;
    this.model.whf_adresse = data['whf_adresse'];
    this.model.whf_description = data['whf_description'];
    this.model.whf_email = data['whf_email'];
    this.model.whf_ncontribuable = data['whf_ncontribuable'];
    this.model.whf_nom = data['whf_nom'];
    this.model.whf_postal = data['whf_postal'];
    this.model.whf_tel = data['whf_tel'];
    this.model.whf_ville = data['whf_ville'];

    $('#home-tab').tab('show');
  }

}
