import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProblemeDailModel {

  public prob_libelle : string;
  public prob_date : string;
  public prob_declarer : string;
  public prob_proj_id : string;
  public prob_assigne : string;
  public prob_evolution : string;
  public prob_depense : string;
  public prob_date_resolu : string;
}
