import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GroupmodelService} from "../model/groupmodel.service";
import {_SERVEROOT} from "../../routes/server-root";
declare let Promise : any;
@Injectable({
  providedIn: 'root'
})
export class GroupctrlService {

  constructor(public http: HttpClient,
    public model: GroupmodelService) {
  }
  public getJournalier(){
    return new Promise ((resolve,reject) => {
      this.http.get(_SERVEROOT.getclientGroup)
          .subscribe(
              dataClientgroup => {
                resolve(dataClientgroup);
              },
              err => {
                reject(err);
              }
          )
    });
  }
 /*  public get(projet:string){
    return new Promise ((resolve,reject)=>{
      this.http.get(_SERVEROOT.getgroupCons+"?projet="+projet)
      .subscribe(
        datagroup =>{
          resolve(datagroup);
        },
        err =>{
          reject(err);
        }
        )
    });


  } */
  public add(){
    return this.http.post(_SERVEROOT.categories_post,this.model);
  }

  public get(){
    return this.http.get(_SERVEROOT.categories_get);
  }

  public get_id(){
    return this.http.get(_SERVEROOT.categories_get_id);
  }

  public update(){
    return this.http.put(_SERVEROOT.categories_update,this.model);
  }

  public delete(){
    return this.http.delete(_SERVEROOT.categories_delete);
  }
}
