import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectmodelService {
  public proj_id : string;
  public proj_lib : string;
  public proj_sup_id : string;
  public proj_date_debut ;
  public proj_date_fin ;
  public proj_desc : string;
  public proj_cat_id : string;
  public proj_id_pays : string;
  public proj_localisation : string;
  public proj_info_client : string;


  constructor() { }
}
