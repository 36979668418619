import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ClientService } from 'src/app/database/controller/wh/client.service';
import { EntrepotService } from 'src/app/database/controller/wh/entrepot.service';
import { KitController } from 'src/app/database/controller/wh/kit.service';
import { MigrationService } from 'src/app/database/controller/wh/migration.service';
import { ProduitService } from 'src/app/database/controller/wh/produit.service';
import { StocksService } from 'src/app/database/controller/wh/stocks.service';
import { ClientModel } from 'src/app/database/model/wh/client.service';
import { StocksModel } from 'src/app/database/model/wh/stocks.service';
import { ExcelService } from 'src/app/services/excel.service';
declare let $ : any;

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.css']
})
export class StockComponent implements OnInit {
  public entrepot : any = [];
  public modelEnt:string;
  public list:number;
  public tableEntrant:any = [];
  public tableKit:any = [];
  public tableKitDetail:any = [];
  public totalfinancier:any = [];
  public totalI: number = 0;
  opening : boolean;
  typeAction : number;
  searchText = '';


  public valVenteForm:any = [];
  public modal : number = 0;
  public AjoutProd : number = 0;
  public tableclient : any = [];
  approForm;
  migrationForm;
  returnedArray: string[];
  public ajoutProdList:any = [];
  public entProdAjout:any = [];
  public autreProv:boolean =false;
  public local_value : any ;


  constructor(
   public entrepotController : EntrepotService,
   public stockController : StocksService,
   public clientService: ClientService,
   public model : ClientModel,
   public modelStock : StocksModel,
   public produitservice : ProduitService,
   public migrationService : MigrationService,
   public kitService : KitController,
   private excelService:ExcelService

  ) {
    this.approForm = {
      val : null,
      fournisseur : null,
      date:null
    };

    this.migrationForm = {
      qteT:null,
      qte : null,
      initial:null,
      final:null,
      user:null,
      code:null,
      date:null

    };
  }

  ngOnInit(): void {
    this.EntrepotList();
    this.list = 0;
    this.AjoutProd = 0;
    this.ajoutProdList = [];
    this.local_value = JSON.parse(localStorage.getItem('monitoring_storage'));
  }


  public qteEntrant(codeproduit:string,qte:number,pv:number,ent:string,stock:any,tinitial:any){

     this.stockController.postApprovisionnement({
       secret:codeproduit,
       user: JSON.parse(localStorage.getItem('monitoring_storage')).user_name,
       fournisseur:this.approForm.fournisseur,
       entrepot:ent,
       qte:this.approForm.val,
       date:this.approForm.date,
       codes:stock,
       montantpv:pv,
       valueiniale:qte,
       Tinitial:tinitial
     }).subscribe(
       (response:any)=>{
         if(response.status == 200){
           this.modal = 0;
           this.returnedArray = this.tableEntrant.slice(0, 10);
           this.liste();
           alert('Approvisionnemnt effectué');
         }
       },(error)=>{
         if(error.status == 422){
           alert('Veuillez renseigner tous les champs.');
         }
       }
     )
   }

   public postMigrer(codeproduit:string,qte:number,entrepot:string,data:any){

    this.migrationForm.qteT = qte ;
    this.migrationForm.initial = entrepot;
    this.migrationForm.user = JSON.parse(localStorage.getItem('monitoring_storage')).user_name;
    this.migrationForm.code = codeproduit;

    if(data.qteT >= data.qte){
      this.migrationService.add(data).subscribe(
        (response:any)=>{
          if(response.status == 200 ){
            alert(response.message);

            if(response.data == 1){
              this.migrationForm.reset();
              this.liste();
            }
          }

        }
      )
    }else{
      alert("La quantité que vous voulez migrer n'est pas disponible");
    }

  }

  public migrerKit(){
    let data = {
      'table'     : this.tableKitDetail,
      'date'      : this.modelStock.whs_date,
      'entrepot'  : this.modelStock.whs_codentrepot,
      'user'      : JSON.parse(localStorage.getItem('monitoring_storage')).user_name
    }

    this.migrationService.kit_add(data).subscribe(
      (response:any)=>{
        if(response.status == 200 ){
          alert(response.message);
          this.ajoutProdList = [];
          this.modelStock.whs_date = "";
          this.modelStock.whs_codentrepot = "";
          $('#myModalKit').modal('hide');

          /* if(response.data == 1){
            this.migrationForm.reset();
            this.liste();
          } */
        }

      }
    )
  }


  public ajouter(id:any,code:any,designation:string,qte:number,mnt:string){

    let t :any = document.getElementById("inputy"+id);
    let m:any = document.getElementById("montant"+id);
    let puPrincipale = parseInt(mnt.replace(/ /g,""));


    if(m.value == 0){
      m.value = parseInt(mnt.replace(/ /g,""));

    }
      let mt = t.value * m.value;
      this.valVenteForm.push({qte:t.value,codeStock:code,desig:designation,montant:m.value,monT:mt,puPrincipale:puPrincipale});

    //this.valVenteForm.push({qte:qte,codeStock:code,desig:designation,montant:mnt,monT:0});

    //this.codeVenteForm.push(code);

  }

  public ajouterKit(id:any,code:any,designation:string,qte:number,mnt:string,provenance:string,id_produit:string){
    let t :any = document.getElementById("inputy"+id);
    let m:any = document.getElementById("montant"+id);
    let puPrincipale = parseInt(mnt.replace(/ /g,""));

    if(m.value == 0){
      m.value =  parseInt(mnt.replace(/ /g,""));;
    }

    if(t.value > qte){
      alert('La quantité entrer est indisponible');
    }else{
      this.tableKitDetail['details'].push({
        whkit_provenance:provenance,
        produit:designation,
        whkit_qte_prod:t.value,
        whkit_pu_prod:m.value,
        whkit_stock_code:code,
        whkit_id_prod:id_produit
      });
    }

  }



  public supprimer(id:number){
    this.valVenteForm.splice(id,1);
  }

  public delete(id:number){
    this.tableKitDetail['details'].splice(id,1);
  }

  EntrepotList(){
    this.entrepotController.get().subscribe(
      (response:any)=>{

        this.entrepot = response;
      }
    )
  }

  public sortieMateriel(){

    this.stockController.sortieMateriel({
      qtesortant:this.valVenteForm,
      user:JSON.parse(localStorage.getItem('monitoring_storage')).user_name,
      entrepot:this.modelEnt,
      date:this.model.date,
      libelle : this.model.resp

    }).subscribe(
      (response:any)=>{
        if(response.status == 200){
        }
      }
    )
  }

  public ancienClient(){
    this.stockController.ancienClient({
      qtesortant:this.valVenteForm,
      user:JSON.parse(localStorage.getItem('monitoring_storage')).user_name,
      resp:this.model.resp,
      client:this.model.code,
      entrepot:this.modelEnt,
      typeversement:this.model.typeversement,
      date:this.model.date
     }).subscribe(
        (response:any)=>{
          if(response.status == 200){
            this.modal = 1;
            this.valVenteForm = [];
            this.liste();
            alert('La vente a été enregistré.')
            $('#myModal').modal('hide');
          }
        },(error)=>{
          if(error.status == 422){
            alert('Vente effectué');
          }
        }
      )
}

  terminer(){
    this.modal = 1;
    this.getClient();
  }

  getClient(){
    this.clientService.get().subscribe(
      (response:any)=>{
        if(response.status == 200){
          this.tableclient = response.data;
        }
      }
    )
  }

  venteClient(){
    this.AjoutProd = 2;
    this.getClient();
  }

  liste(){
      if( this.modelEnt == "Kit"){
        this.kitService.list().subscribe(
          (response:any) => {
            if(response.status == 200){
              this.list = 0;
              this.tableKit = response.data
              this.tableEntrant = [];
            }
          }
        )
      }else{
        this.stockController.list({modelEnt : this.modelEnt}).subscribe(
          (response:any)=>{
            if(response.status == 200){
              this.list = 10;
              this.tableKit = [];
              this.tableKitDetail = [];
              this.tableEntrant = response.data.prod;
              this.totalfinancier = response.data.total;
              this.totalI = response.data.totalI;
            }
          }
        )
      }



  }

  public listAjoutProd(){
    this.stockController.list({modelEnt : this.entProdAjout}).subscribe(
      (response:any)=>{
        if(response.status == 200){
          this.ajoutProdList = response.data.prod;
        }
      }
    )


  }

  modalShow(){
    $('#myModal').modal('show');
  }
  modalKitShow(data:any){
    $('#myModalKit').modal('show');
    this.tableKitDetail = data;
  }




  public qtesortant(){
    this.stockController.postEntrant({
      nom:this.model.nom,
      email:this.model.email,
      contact:this.model.contact,
      adresse:this.model.adresse,
      qtesortant:this.valVenteForm,
      entrepot:this.modelEnt,
      user:JSON.parse(localStorage.getItem('monitoring_storage')).user_name,
      resp:this.model.resp,
      typeversement:this.model.typeversement,
      date:this.model.date
    }).subscribe(
      (response:any)=>{
        if(response.status == 200){
          this.modal = 0;
            this.valVenteForm = [];
            this.liste();
            $('#myModal').modal('hide');
            alert('Vente effectuée');
        }
      }
    )



  }

  public vendKit(){
    this.stockController.kitVente({
      qtesortant:this.tableKitDetail,
      user:JSON.parse(localStorage.getItem('monitoring_storage')).user_name,
      resp:this.model.resp,
      client:this.model.code,
      entrepot:this.modelEnt,
      typeversement:this.model.typeversement,
      date:this.model.date
    }).subscribe(
      (response:any)=>{
        if(response.status == 200){
          alert('La vente a bien été effectué');
          this.ajoutProdList = [];
          this.model.code = "";
          this.model.contact = "";
          this.model.typeversement = "";
          this.model.resp = "";
          $('#myModalKit').modal('hide');
        }
      }
    )
  }


  public exportStock(){
    this.excelService.generateExcelStockEntrepot({modelEnt : this.modelEnt});
  }



}
