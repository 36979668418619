import { Component, OnInit } from '@angular/core';
import { ActiviteDailModel } from 'src/app/database/model/activitedailmodel.service';
import { ActivitesService } from 'src/app/database/controller/activites.service';
import { EtapesService } from 'src/app/database/controller/etapes.service';
import { ProjectmodelService } from 'src/app/database/model/projectmodel.service';
import { ProjectctrlService } from 'src/app/database/controller/projectctrl.service';
import { ProblemsCrt } from 'src/app/database/model/problems.service';
import { EmployeController } from 'src/app/database/controller/employe.service';
import { ProblemsService } from 'src/app/database/controller/problems.service';
import { DepensemodelService } from 'src/app/database/model/depensemodel.service';
import { DepenseService } from 'src/app/database/controller/depense.service';


@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {
  public tabs:any = [];
  public tabs_step:any= [];
  public projetTabs : any = [];
  public taches = null;
  public projet = null;
  public id_projet = null;
  public etape = null;
  public tab_activite = null;
  public tecTabs = null;
  public probTabs = null;
  public depenseTabs = null;
  public depTotal = null;

  constructor(
    //public model : ActiviteDailModel,
    public controllerAct : ActivitesService,
    public controller : ProjectctrlService,
    public controllerEtapes : EtapesService,
    public model : ProjectmodelService ,
    public problemsModel : ProblemsCrt,
    public EmployeProjet : EmployeController ,
    public problemController : ProblemsService,
    public depenseModel : DepensemodelService,
    public depenseController : DepenseService,
  ) { }

  ngOnInit(): void {
    this.projetGet();
    this.TechnicienGet();
    //this.stepsGet();
  }

  projetGet(){
    this.controller.get().subscribe(
      (response: any) => {
        this.projetTabs = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  projetGet_id(){
    this.controller.get_id().subscribe(
      (response: any) => {
        this.projet = response.projet;
        this.taches = response.taches;
      },
    )
  }

  activityGet(){
    this.controllerAct.get(this.id_projet).subscribe(
      (response: any) => {
        this.tabs = response.activites;
        this.etape = response.etape;
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  modalComplet(tab){
    this.tab_activite = tab;
    console.log(tab);
  }

  TechnicienGet(){
    this.EmployeProjet.getTech().subscribe(
      (response: any) => {
        this.tecTabs = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  issuesAdd(){

    this.problemsModel.prob_act_id = this.tab_activite.act_id;

    this.problemController.add().subscribe(
      (response:any) =>{
        alert('Le probléme a bien été enregistré');
        this.problemsModel.prob_date = "";
        this.problemsModel.prob_declarer = "";
        this.problemsModel.prob_libelle = "";
      },
      (error)=>{
        alert('Une erreur est survenue. veuillez vous reconnecter plustard');
        console.log(error);
      }
    )

  }

  issuesList(){
    this.problemController.get(this.etape.etap_id).subscribe(
      (response:any)=>{
        this.probTabs = response.data;
      },
      (error)=>{
        alert('Une erreur est survenue. veuillez vous reconnecter plustard');
        console.log(error);
      }
    )
  }

  depenseAdd(){
    this.depenseModel.depense_act_id = this.tab_activite.act_id;

    this.depenseController.add().subscribe(
      (response:any) =>{
        this.depenseModel.depense_commentaire = "";
        this.depenseModel.depense_libel = "";
        this.depenseModel.depense_mont = "";
        this.depenseModel.depense_date = "";
        alert('La dépense a bien été enregistré')

      }
    )
  }

  depenseList(){
    this.depenseController.get(this.etape.etap_id).subscribe(
      (response:any)=>{
        this.depenseTabs = response.data;
        this.depTotal = response.total;
      }
    )

  }

  supDep(data:any){
    this.depenseController.delete(data.depense_id).subscribe(
      (response:any)=>{
        if(response.status == 200){
          this.depenseList();
         // $('.bd-example-modal-sm').hide();
        }
      }
    )
  }



}
