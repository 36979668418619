import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';

@Injectable({
  providedIn: 'root'
})
export class OutilsServicemt {

  constructor(
    public http : HttpClient,
  ) { }

  public list(projet:any){
    return this.http.post(_SERVEROOT.outils_list, projet);
  }
}
