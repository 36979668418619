import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-appchoice',
  templateUrl: './appchoice.component.html',
  styleUrls: ['./appchoice.component.css']
})
export class AppchoiceComponent implements OnInit {

  @Input() name  : string;
  constructor() { }

  ngOnInit() {
  }

}
