import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';

@Injectable({
  providedIn: 'root'
})
export class ApprovisionnementService {

  constructor(
    public http: HttpClient,
  ) { }

  public add(data:any){
    return this.http.post(_SERVEROOT.whpprovisonnements_post,data);
  }

  public get(){
    return this.http.get(_SERVEROOT);
  }

  public excelglobale(){
    return this.http.get(_SERVEROOT.whpprovisonnements_export_globale);
  }

  public excelentrepot(){
    return this.http.get(_SERVEROOT.whpprovisonnements_export_entrepot);
  }

}
