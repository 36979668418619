import { Component, OnInit } from '@angular/core';
import { EntrepotService } from 'src/app/database/controller/wh/entrepot.service';
import { MigrationService } from 'src/app/database/controller/wh/migration.service';

@Component({
  selector: 'app-bilan-financier',
  templateUrl: './bilan-financier.component.html',
  styleUrls: ['./bilan-financier.component.css']
})
export class BilanFinancierComponent implements OnInit {
  public entrepot:any=[];
  public modelEnt:string;
  public modal : number;
  public totalPRestant : any=[];
  public totalVendu : Array<any>=[];
  public totalRestant : Array<any> =[];
  public totalPVendu : any=[];
  public desiEnt:any = [];
  public tabprodbilan:any = [];

  constructor(
    public entrepotservice:EntrepotService,
    public bilanservice:MigrationService
  ) { }

  ngOnInit(): void {
    this.selectentrepot();
    this.modal=0;
    this.postbilan();
  }

  public selectentrepot(){
    this.entrepot =[];
    this.entrepotservice.get().subscribe(
      (response:any)=>{
        if(response.status == 200){
          this.entrepot=response.data.entrepot;
        }
      }
    )
  }

  public postbilan(){
    this.modal=1;
   this.bilanservice.bilan().subscribe(
     (response:any)=>{
       if(response.status == 200){

         response.data.total.forEach(element => {
           this.totalVendu.push(element);
         });
         this.totalPVendu = response.data.totalPVendu;
         this.totalPRestant = response.data.totalPRestant;
       }
     }
   )

  }

  public postbilanproduit(){
    this.bilanservice.bilanProduit().subscribe(
      (response:any)=>{
        if(response.status == 200){
          this.tabprodbilan = response.data;
        }

      }
    )
  }

}
