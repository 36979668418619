import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homedaily',
  templateUrl: 'homedaily.component.html',
  styleUrls: ['homedaily.component.css']
})
export class HomedailyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
