import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';
import { StocksModel } from '../../model/wh/stocks.service';

@Injectable({
  providedIn: 'root'
})
export class StocksService {

  constructor(
    public http: HttpClient,
    public model: StocksModel
  ) { }

  public add(){
    return this.http.post(_SERVEROOT,this.model);
  }

  public list(data:any){
    return this.http.post(_SERVEROOT.whstocks_post_list,data);
  }

  public ancienClient(data:any){
    return this.http.post(_SERVEROOT.whstocks_post_ante_an,data);
  }

  public postEntrant(data:any){
    return this.http.post(_SERVEROOT.whstocks_post_new_vente,data);
  }

  public postApprovisionnement(data:any){
    return this.http.post(_SERVEROOT.whstocks_post_approvionnement,data);
  }

  public sortieMateriel(data:any){
    return this.http.post(_SERVEROOT.whstocks_vente_ancien,data);
  }

  public kitVente(data:any){
    return this.http.post(_SERVEROOT.whkit_vente,data);
  }



}
