import { Component, OnInit } from '@angular/core';
import {_SERVEROOT} from "../../routes/server-root";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
declare let $ : any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public email : string;
  public password : string;

  constructor(public http : HttpClient,public router : Router) {

  }

  ngOnInit() {
  }


  loginPost(){
    let me = this;
    $.ajax({
      url :_SERVEROOT.login,
      type : 'POST',
      dataType:'JSON',
      data: {
        "login" : me.email,
        "pwd" : me.password,
      },
      success:function (data) {
        if(data.status == 200) {
			if(data.data.role !="admin"){
				alert("Vous n'avez pas le niveau pour accéder à cette application.");
				return;
			}
          let expiredDate = new Date();
          let ck : any;
          expiredDate.setDate(expiredDate.getDate() + 10 );
          localStorage.setItem('monitoring_storage',JSON.stringify(data.data));
          setTimeout(()=>{
            ck = localStorage.getItem('monitoring_storage');
            if(ck) {
              console.log('hjnjbkbjn '+ck);
              me.router.navigate(['/project/view']);
              //location.reload(true);
            }
          },2000);
          /* me.cookieService.set('__asfaserv', JSON.stringify(data.data),expiredDate,'/');
          setTimeout(()=>{
            ck = me.cookieService.get('__asfaserv');
            if(ck) {
              me.router.navigate(['/project/view']);
              location.reload(true);
            }
          },2000); */
        }else{
          alert(data.message);
        }
      },
      error:function () {
        alert('Une erreur est survenue');
      }
    });
  }

}
