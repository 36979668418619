import { Component, OnInit } from '@angular/core';
import { DepenseService } from 'src/app/database/controller/depense.service';
import { ProjectctrlService } from 'src/app/database/controller/projectctrl.service';
import { TachectrlService } from 'src/app/database/controller/tachectrl.service';
import { DepensemodelService } from 'src/app/database/model/depensemodel.service';
import { TachemodelService } from 'src/app/database/model/tachemodel.service';

@Component({
  selector: 'app-spent',
  templateUrl: './spent.component.html',
  styleUrls: ['./spent.component.css']
})
export class SpentComponent implements OnInit {

  public projetTabs : any = [];
  public depenseTabs : any = [];
  public totaldpense : number = 0;
  public etapeTabsId :any = [];

  constructor(
    public projetController: ProjectctrlService,
    public depenseModel : DepensemodelService,
    public depenseController : DepenseService,
    public tacheController : TachectrlService,
    public model :TachemodelService
  ) { }

  ngOnInit(): void {
    this.projetGet();
  }

  projetGet(){
    this.projetController.get().subscribe(
      (response: any) => {
        this.projetTabs = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }


  depenseAdd(){
    this.depenseModel.depense_projet_id = this.model.tache_proj_id;
    this.depenseController.add().subscribe(
      (response:any) =>{
        alert('Opération réussie')
        this.depenseModel.depense_projet_id = "";
        this.depenseModel.depense_libel = "";
        this.depenseModel.depense_mont = "";
        this.depenseModel.depense_commentaire = "";
      }
    )
  }

  listDepense(){
    this.depenseController.get(1).subscribe(
      (response:any) =>{
        if(response.status == 200){
          this.depenseTabs = response.data;
          this.totaldpense = response.total;
        }
      }
    )
  }

  tacheProjet(){
    this.tacheController.projet_tache().subscribe(
      (response:any) =>{
        this.etapeTabsId = response.etapes;
      }
    )
  }

}
