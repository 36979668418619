import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserModel {
  public user_name : string;
  public user_email : string;
  public user_phone : string;
  public user_pwd : string;
  public user_type : string;
  public user_cond : string;
}
