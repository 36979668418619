import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/database/controller/wh/client.service';
import { DevisService } from 'src/app/database/controller/wh/devis.service';
import { KitController } from 'src/app/database/controller/wh/kit.service';
import { DevisModel } from 'src/app/database/model/wh/devis.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-devis',
  templateUrl: './devis.component.html',
  styleUrls: ['./devis.component.css']
})
export class DevisComponent implements OnInit {
public pageDevis: number = 0;
public clientPage : number = 0;
public detailPage : number = 0;
public articlePage : number = 0;
public depensePage : number = 0;
public photoPage : number = 0;
public varkit : any;
public tabs : any = [];
public tableKit : any = [];
public tableKitdetail : any = [];
dropdownList = [];
selectedItems = [];
dropdownSettings : IDropdownSettings;


  constructor(
    public controller : DevisService,
    public model : DevisModel,
    public router : Router,
    public controllerClient : ClientService,
    public kitService : KitController
  ) { }

  ngOnInit(): void {
    this.pageDevis = 0;
    this.clientPage = 0;
    this.detailPage = 0;
    this.articlePage = 0;
    this.depensePage = 0;
    this.photoPage = 0;


    this.dropdownList = [
      { item_id: 1, item_text: 'Mumbai' },
      { item_id: 2, item_text: 'Bangaluru' },
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' },
      { item_id: 5, item_text: 'New Delhi' }
    ];
    this.selectedItems = [
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }

  public creer(){
    this.controller.create().subscribe(
      (response:any)=>{
        if(response.status == 200){
          this.model.whdev_code = response.data['whdev_code'];
          this.model.whdev_date = response.data['whdev_date'];
          this.model.whdev_total = '0 CFA';
          this.model.whdev_rabais = '0 CFA';
          this.model.whdev_letotal = '0 CFA';
          this.pageDevis = 1;
        }
      }
    )
  }

  public clientBtn(){
    this.controllerClient.get().subscribe(
      (response:any)=>{
        if(response.status == 200){
          this.tabs = response.data;
          this.clientPage = 1;
          this.detailPage = 0;
          this.articlePage = 0;
          this.depensePage = 0 ;
        }

      }
    )
  }

  public projetBtn(){
    this.clientPage = 0;
    this.detailPage = 1;
    this.articlePage = 0;
    this.depensePage = 0;
  }

  public articleBtn(){
    this.clientPage = 0;
    this.detailPage = 0;
    this.articlePage = 1;
    this.depensePage = 0;

    this.kitService.list().subscribe(
      (response:any) => {
        if(response.status == 200){
          this.tableKit = response.data
        }
      }
    )
  }

  public kitDetail(){
    this.tableKit.forEach(element => {
      if(this.varkit == element['whkit_code']){
        this.tableKitdetail = element;
      }
    });
  }

  public depenseBtn(){
    this.clientPage = 0;
    this.detailPage = 0;
    this.articlePage = 0;
    this.depensePage = 1;
  }



}
