import { Injectable } from '@angular/core';
import {Router, ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class WarehouseGuardService {

  constructor(public router : Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (localStorage.getItem('monitoring_storage') && JSON.parse(localStorage.getItem('monitoring_storage')).user_type == 'warehouse'){
      return true
    }
    this.router.navigate(['/login']);
    return false;
  }


}
