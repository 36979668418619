import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from "@angular/forms";
import { RouterModule, Routes } from '@angular/router';
import { UsersComponent } from './component/users/users.component';
import { FournisseursComponent } from './component/fournisseurs/fournisseurs.component';
import { EntrepotComponent } from './component/entrepot/entrepot.component';
import { CategorieComponent } from './component/categorie/categorie.component';
import { ProduitComponent } from './component/produit/produit.component';
import { StockComponent } from './component/stock/stock.component';
import { ClientComponent } from './component/client/client.component';
import { ApprovisionnementComponent } from './component/approvisionnement/approvisionnement.component';
import { VentesComponent } from './component/ventes/ventes.component';
import { MigationsComponent } from './component/migations/migations.component';
import { BilanFinancierComponent } from './component/bilan-financier/bilan-financier.component';
import { LoginWahComponent } from './login/login.component';
import { KitComponent } from './component/kit/kit.component';
import { DevisComponent } from './component/devis/devis.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { WhoutilsComponent } from './component/whoutils/whoutils.component';


@NgModule({
  declarations: [
    UsersComponent,
    FournisseursComponent,
    EntrepotComponent,
    CategorieComponent,
    ProduitComponent,
    StockComponent,
    ClientComponent,
    ApprovisionnementComponent,
    VentesComponent,
    MigationsComponent,
    BilanFinancierComponent,
    LoginWahComponent,
    KitComponent,
    DevisComponent,
    WhoutilsComponent,
  ],
  exports: [
    UsersComponent,
    FournisseursComponent,
    EntrepotComponent,
    CategorieComponent,
    ProduitComponent,
    StockComponent,
    ClientComponent,
    ApprovisionnementComponent,
    VentesComponent,
    MigationsComponent,
    BilanFinancierComponent,
    LoginWahComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    Ng2SearchPipeModule,

  ]
})
export class WarehouseModule {}
