import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClientModel {
 /*  public whcl_code : string;
  public whcl_nom : string;
  public whcl_email : string;
  public whcl_contact : string;
  public whcl_adresse : string;
  public whcl_user : string; */

  public nom:string;
  public codeproduit:string;
  public email:string;
  public contact:string;
  public code:string;
  public adresse:string;
  public qtesortant:number;
  public resp:string;
  public somme:number;
  public codentrepot :string;
  public user :string;
  public pv :number;
  public date:Date;
  public codeclient :string;
  public  datedebut: Date;
  public  datefin: Date;
  public  datedeb: Date;
  public  datef: Date;
  public typeversement:string;
}
