import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EtapeDailModel {
  public etap_libelle : string;
  public etap_desc : string;
  public etap_cat_id : string;
}
