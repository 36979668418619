import { Component, OnInit } from '@angular/core';
import {EmployeController} from "../../../database/controller/employe.service";
import {EmployeService} from "../../../database/model/employe.service";

@Component({
  selector: 'app-chief',
  templateUrl: './chief.component.html',
  styleUrls: ['./chief.component.css']
})
export class ChiefComponent implements OnInit {
  public tabs : any = [];
  public tabsTech : any = [];

  constructor(
    public model : EmployeService ,
    public controller : EmployeController
    ) {
      this.model.sup_user_id = JSON.parse(localStorage.getItem('monitoring_storage')).user_id;
    }

  ngOnInit() {
  }

  supervisseurAdd(){
    this.controller.add().subscribe(
      (response:any) =>{
        alert('L\'opération a été enregistré');
        this.model.sup_email = "";
        this.model.sup_nom = "";
        this.model.sup_phone = 0;
        this.model.sup_role = "";
        this.model.sup_user_id = "";
      },
      (error)=>{
        alert('Une erreur est survenue. veuillez vous reconnecter plustard');
        console.log(error);
      }
    )
  }

  supervisseurGet(){
    this.controller.get().subscribe(
      (response: any) => {
        this.tabs = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  techniciensGet(){
    this.controller.getTech().subscribe(
      (response: any) => {
        this.tabsTech = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }





}
