import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _SERVEROOT } from 'src/app/routes/server-root';

@Injectable({
  providedIn: 'root'
})
export class DevisService {

  constructor(
    public http: HttpClient,
  ) { }

  public create(){
    return this.http.get(_SERVEROOT.whdevis_get);
  }
}
