import { Component, OnInit } from '@angular/core';
import { DepenseService } from 'src/app/database/controller/depense.service';
import { DonneesousactService } from 'src/app/database/controller/donneesousact.service';
import { EtapesService } from 'src/app/database/controller/etapes.service';
import { FichierService } from 'src/app/database/controller/fichier.service';
import { ProjectctrlService } from 'src/app/database/controller/projectctrl.service';
import { SousactivitesService } from 'src/app/database/controller/sousactivites.service';
import { TachectrlService } from 'src/app/database/controller/tachectrl.service';
import { TachemodelService } from 'src/app/database/model/tachemodel.service';

@Component({
  selector: 'app-monitoring-projet',
  templateUrl: './monitoring-projet.component.html',
  styleUrls: ['./monitoring-projet.component.css']
})
export class MonitoringProjetComponent implements OnInit {

  public etapeTabsId : any = [];
  public projetTabs : any = [];

  public image :any = null;

  public activityTabs : any = [];
  public depenseTabs : any = [];
  public fichierTabs : any = [];
  public problemeTabs : any = [];
  public depenseTot : any = 0;

  public booldepense : boolean = false;
  public boolprob : boolean = false;
  public boolact : boolean = false;
  public boolfichier : boolean = false;

  public projet : any = null;
  public etape : any = null;
  public activite : any = null;

  public actiModel : any = null;
  imageToShow: any;
  isImageLoading = false;


  constructor(
    public projetController: ProjectctrlService,
    public tacheController : TachectrlService,
    public depenseController : DepenseService,
    public sousActController : DonneesousactService,
    public fichierController : FichierService,

    public model :TachemodelService,


  ) { }

  ngOnInit(): void {
    this.projetGet();
    this.booldepense = false;
    this.boolprob  = false;
    this.boolact = false;
    this.boolfichier  = false;

  }

  projetGet(){
    this.projetController.get().subscribe(
      (response: any) => {
        this.projetTabs = response;
      },
      (error)=>{
        console.log(error);
      }
    )
  }


  monitProjet(){
    this.tacheController.projet_tache().subscribe(
      (response:any) =>{
        this.projet = response.projet;
        this.etapeTabsId = response.etapes;
      }
    )
  }


  monitEtapes(){
    this.sousActController.list().subscribe(
      (response:any) =>{
        console.log(response)
        this.etape = response.etape;

        this.activityTabs = response.activite;
        this.depenseTabs = response.depense;
        this.fichierTabs = response.fichier;
        this.problemeTabs = response.probleme;
        this.depenseTot = response.totalDepense;
      }
    )
   /*  this.depenseController.list_activity().subscribe(
      (response:any) =>{
        this.etape = response.etape;
        this.activityTabs = response.activites;
      }
    ) */
    /* tacheController.etape_tache().subscribe(
      (response:any) =>{
        this.etape = response.etape;
        this.activityTabs = response.activites;
        console.log(response)
      }
    ) */
  }

  /* sousActList(){
    this.sousActController.list(this.actiModel).subscribe(
      (response:any) =>{
        this.activite = response.activite;
        this.sousactivityTabs = response.data;
        console.log(this.sousactivityTabs[0].details);
      }
    )
  } */

  /* depenseList(){
    this.depenseController.list(this.actiModel).subscribe(
      (response:any) =>{
        this.depenseTabs = response.data;
      }
    )
  } */

  /* fichierList(){
    this.fichierController.list(this.actiModel).subscribe(
      (response:any) =>{
        this.fichierTabs = response.data;
        console.log(response)
      }
    )
  } */

  imgAffiche(img){
    let table = {
      image : img
    }

    this.fichierController.affichephoto(table).subscribe(
      (response:any) =>{
        window.open(response.data, "_blank");
        //this.image = response.data
      }
    )

   /*

    this.fichierController.affichephoto(table).subscribe(
      (response:any) =>{
        window.open(response.data, "_blank");
        this.image = response.data
      }
    ) */
  }









}
