import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EmployeController} from "./controller/employe.service";
import {EmployeService} from "./model/employe.service";
import {ProjectctrlService} from "./controller/projectctrl.service";
import {ProjectmodelService} from "./model/projectmodel.service";
import {GroupctrlService} from "./controller/groupctrl.service";
import {GroupmodelService} from "./model/groupmodel.service";
import {TachectrlService} from "./controller/tachectrl.service";
import {TachemodelService} from "./model/tachemodel.service";
import {AgentmappingmodelService} from "./model/agentmappingmodel.service";
import {ProblemsService} from "./controller/problems.service";
import {ProblemsCrt} from "./model/problems.service";
import  {PointageService} from "./controller/pointage.service";
import { PointService } from './model/pointage.service';
import { ProblemeDailModel } from './model/problemedailmodel.service';
import { CategorieDailModel } from './model/categoriesdailmodel.service';
import { DevisDailModel } from './model/devisdailmodel.service';
import { EtapeDailModel } from './model/etapdailmodel.service';
import { ActiviteDailModel } from './model/activitedailmodel.service';
import { SousActiviteDailModel } from './model/sousactivitedailmodel.service';
import { SupervisseurDailModel } from './model/supervisseurdailmodel.service';
import { UserModel } from './model/user.service';
import { ActivitesService } from './controller/activites.service';
import { CategoriesService } from './controller/categories.service';
import { EtapesService } from './controller/etapes.service';
import { SousactivitesService } from './controller/sousactivites.service';
import { UsersService } from './controller/users.service';
import { DepenseService } from './controller/depense.service';
import { DonneesousactService } from './controller/donneesousact.service';
import { FichierService } from './controller/fichier.service';
import { DepensemodelService } from './model/depensemodel.service';
import { DonneesousactmodelService } from './model/donneesousactmodel.service';
import { FichiermodelService } from './model/fichiermodel.service';
import { ApprovisionnementModel} from './model/wh/approvisionnement.service';
import { CategorieModel } from './model/wh/categorie.service';
import { ClientModel } from './model/wh/client.service';
import { EntrepotModel } from './model/wh/entrepot.service';
import { FournisseurModel } from './model/wh/fournisseur.service';
import { MigrationModel } from './model/wh/migration.service';
import { ProduitModel } from './model/wh/produit.service';
import { StocksModel } from './model/wh/stocks.service';
import { VentesModel } from './model/wh/ventes.service';
import { ApprovisionnementService } from './controller/wh/approvisionnement.service';
import { CategorieService } from './controller/wh/categorie.service';
import { ClientService } from './controller/wh/client.service';
import { EntrepotService } from './controller/wh/entrepot.service';
import { FournisseurService } from './controller/wh/fournisseur.service';
import { MigrationService } from './controller/wh/migration.service';
import { ProduitService } from './controller/wh/produit.service';
import { StocksService } from './controller/wh/stocks.service';
import { VentesService } from './controller/wh/ventes.service';
import { OutilsService } from './controller/wh/outils.service';
import { OutilsServicemt } from './controller/outils.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers :[
    AgentmappingmodelService,
    ProjectctrlService,
    GroupctrlService,
    GroupmodelService,
    TachectrlService,
    TachemodelService,
    ProjectmodelService,
    EmployeService,
    EmployeController,
    ProblemsService,
    ProblemsCrt,
    PointageService,
    PointService,
    ActiviteDailModel,
    CategorieDailModel,
    DevisDailModel,
    EtapeDailModel,
    ProblemeDailModel,
    ProjectmodelService,
    SousActiviteDailModel,
    SupervisseurDailModel,
    UserModel,
    ActivitesService,
    CategoriesService,
    EtapesService,
    SousactivitesService,
    UsersService,
    DepenseService,
    DonneesousactService,
    FichierService,
    DepensemodelService,
    DonneesousactmodelService,
    FichiermodelService,
    ApprovisionnementModel,
    CategorieModel,
    ClientModel,
    EntrepotModel,
    FournisseurModel,
    MigrationModel,
    ProduitModel,
    StocksModel,
    VentesModel,
    ApprovisionnementService,
    CategorieService,
    ClientService,
    EntrepotService,
    FournisseurService,
    MigrationService,
    ProduitService,
    StocksService,
    VentesService,
    OutilsService,
    OutilsServicemt


  ]
})
export class DatabaseModule { }
