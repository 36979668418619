import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { _SERVEROOT } from 'src/app/routes/server-root';
declare let $ : any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginDailComponent implements OnInit {
  public email : string;
  public password : string;
  public type : string;

  constructor(
    public http : HttpClient,
    public router : Router) {

  }

  ngOnInit(): void {
  }

  loginPost(){
    let me = this;
    $.ajax({
      url :_SERVEROOT.login,
      type : 'POST',
      dataType:'JSON',
      data: {
        "user_phone" : me.email,
        "user_pwd" : me.password,
      },
      success:function (data) {
        if(data.status == 200) {
          /* if(data.data.role !="admin"){
            alert("Vous n'avez pas le niveau pour accéder à cette application.");
            return;
          } */
          let expiredDate = new Date();
          let ck : any;
          expiredDate.setDate(expiredDate.getDate() + 10 );
          localStorage.setItem('monitoring_storage',JSON.stringify(data.data));
          setTimeout(()=>{
            ck = localStorage.getItem('monitoring_storage');

            if(ck){
              me.router.navigate(['/project/view']);
              /* if(JSON.parse(localStorage.getItem('monitoring_storage')).user_type == 'monitoring') {
                me.router.navigate(['/app/dailyscoring/users']);
              }
              if(JSON.parse(localStorage.getItem('monitoring_storage')).user_type == 'warehouse') {
                me.router.navigate(['/app/warehouse/users']);
              } */
            }


          },2000);
          /* me.cookieService.set('__asfaserv', JSON.stringify(data.data),expiredDate,'/');
          setTimeout(()=>{
            ck = me.cookieService.get('__asfaserv');
            if(ck) {
              me.router.navigate(['/app/dailyscoring/users']);
            }
          },2000); */
        }else{
          alert(data.message);
        }
      },
      error:function () {
        alert('Une erreur est survenue');
      }
    });
  }
}
