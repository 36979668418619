import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategorieModel {
  public whc_designation : string;
  public whc_codecat : string;
  public whc_description : string;
  public 	whc_type:string;

}
