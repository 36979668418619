import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthGuardService} from "./auth-guard.service";
import {MapsService} from "./maps.service";

@NgModule({
  declarations: [],
    providers :[
      AuthGuardService,
      MapsService
  ],
  imports: [
    CommonModule
  ]
})
export class ServicesModule { }
