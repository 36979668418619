import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DevisModel {
  public whdev_id : string;
  public whdev_code : string;
  public whdev_id_client : string;
  public whdev_id_dep : string;
  public whdev_id_article : string;
  public whdev_id_piecj : string;
  public whdev_comment : string;
  public whdev_date : string;
  public whdev_total : string;
  public whdev_rabais : string;
  public whdev_letotal : string;
}
