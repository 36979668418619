import { Component, OnInit } from '@angular/core';
import { MigrationService } from 'src/app/database/controller/wh/migration.service';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-migations',
  templateUrl: './migations.component.html',
  styleUrls: ['./migations.component.css']
})
export class MigationsComponent implements OnInit {
  public tableMigration: any = [];
  searchText;

  constructor(
    public migrationservice:MigrationService,
    private excelService:ExcelService
  ) { }

  ngOnInit(): void {
    this.liste();
  }

  public liste(){
    this.migrationservice.get().subscribe(
      (response:any)=>{
        if(response.status == 200){
          this.tableMigration = response.data;
        }
      }
    )
  }

  public excelMigglobale(){
    this.excelService.generateExcelMig();
  }

  public excelMigentrepot(){
    this.excelService.generateExcelMigEntrepot();
  }

}
